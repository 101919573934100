import { Component, OnInit, OnDestroy } from '@angular/core';

import { AdminService } from '../services/admin.service';
import { LocationService } from '../services/location.service';
import { Location } from '../models/location'
import { AppUser } from '../models/app-user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Extractor } from '@angular/compiler';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import firestore from 'firebase/app';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit, OnDestroy {
  locations$: Observable<Location[]>;
  locations: Location[] = [];
  userLocationRoles: [{}];
  isDirty: boolean = false;
  updating: boolean = false;
  errorUpdating: boolean = false;
  nUser: AppUser;
  private locationSubscription: Subscription;
  constructor(private locationService: LocationService,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private firestore: AngularFirestore) {
    this.nUser = new AppUser();
  }

  userName: string;
  email: string;
  password: string;
  isAdmin: boolean;
  users: AppUser[];
  groups: [{}];
  areas: [{}];

  ngOnInit(): void {

    this.locationSubscription = this.locationService.getLocations().subscribe(
      data => {
        this.locations = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data() as Location
          };//as any;
        })
        this.setGroups();
        //this.updateGroupUsage();
      }
    )



    this.locations = null;
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Location
        };//as any;
      })

      this.areas.pop();// pop the first blank index off

    }); // end locationserve get locations
  }
  createUser() {

    this.nUser.name = this.userName;
    this.nUser.email = this.email;
    this.nUser.isAdmin = this.isAdmin ? true : false; 

    // put a check here to make sure we do not call this if the user already exists
    this.afAuth.createUserWithEmailAndPassword(this.nUser.email, this.password).then(fbuser => {
      fbuser.user.updateProfile({ displayName: this.nUser.name }).then(e => {
        this.firestore.collection('users').doc(fbuser.user.uid).set(
          {
            name: this.nUser.name, email: this.nUser.email, isAdmin: this.nUser.isAdmin,
            isRequestor: false, isEngineer: false, isManager: false
          })
          .then(e => {
            console.log("user created");
            // for (let loc of this.areas) {
            //   for (let group of loc['groups']) {
            //     //console.log(group)
            //     let ids = [];
            //     if (group[group['Role']] == true) {
            //       const tmp = group['Role'];
            //       let data = this.firestore.collection('location', ref => ref.where('name', '==', loc['groupName'])) //match up checkbox role selected to location name
            //         .valueChanges({ idField: 'id' })
            //         .subscribe(data => {

            //           for (let obj in data[0] as any) {
            //             // if(data[0][obj]['users'].push())
            //             if (data[0][obj]['groupName'] == tmp) {
            //               let foundName = false;
            //               for (let u of data[0][obj]['users']) {
            //                 if (u.name == this.nUser.name)
            //                   foundName = true;

            //               } //end for

            //               if (foundName == false) {
            //                 if (data[0][obj]['users'])
            //                   data[0][obj]['users'].push({ name: this.nUser.name, email: this.nUser.email, uid: fbuser.user.uid })
            //                 // for (let i = 0; i < data[0][obj]['users'].length; i++)
            //                 //   console.log(loc['groupName'] + " " + data[0][obj]['users'][i]['name'] +
            //                 //     data[0][obj]['users'][i]['email'] + data[0][obj]['users'][i]['uid'])
            //                 let tmpId = data[0]['id'];
            //                 // const docRef = this.firestore.collection('location').doc(tmpId);
            //                 //  const res = docRef.update(data[0]);

            //               }
            //             }

            //           }

            //         })
            //     }
            //   }
            // }

          })


      })//end update profile then
    })//end of observable createUserWithEmail then

  }

  setGroups() {
    this.groups = [{}];
    this.areas = [{}];
    // this.userLocationRoles = [{}];
    this.areas.pop();// pop the first blank index off
    for (let location of this.locations) {
      //this.areas["Location"] = location;
      this.groups = [{}];
      this.groups.pop(); // pop the blank record
      for (let prop in location) {// if the object has the property

        if (Object.prototype.hasOwnProperty.call(location, prop))
          if (prop.startsWith('group')) {  // and property is one of the groups
            if (location[prop]['groupName']) {
              let prtyname = location[prop]['groupName'];
              let obj = {};
              obj['Role'] = prtyname;
              obj[prtyname] = false
              this.groups.push(obj)
            }
            // console.log(location[prop]['groupName'])
          }
      }
      // this.areas["Location"][location.name] = this.groups;
      this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
      this.areas.push({ groupName: location.name, groups: this.groups });
      //this.areas[location.name]=this.groups;
    }

  }

  updateGroupUsage() {
    try {
      //this.userName = this.userLocationRoles['name'];
      //this.email = this.userLocationRoles['email'];

      for (let location of this.locations) {
        //this.areas["Location"] = location;
        this.groups = [{}];
        this.groups.pop(); // pop the blank record
        for (let prop in location) {// if the object has the property

          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName']) {
                let prtyname = location[prop]['groupName'];
                let obj = {};
                obj['Role'] = prtyname;
                if (this.userLocationRoles[location.name]) {
                  if (this.userLocationRoles[location.name].roles.indexOf(prtyname) != -1) {
                    obj[prtyname] = true;
                  }
                  else {
                    obj[prtyname] = false;
                  }

                }
                else {
                  obj[prtyname] = false
                }

                this.groups.push(obj)
              }
              // console.log(location[prop]['groupName'])
            }
        }
        // this.areas["Location"][location.name] = this.groups;
        this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);

      }
    }
    catch (err) { };
  }

  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
