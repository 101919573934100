interface IApproval{
    groupName: string,
    users: [{name:string,uid:string}]
   
}
export interface ILocation {
    name: string,
    group1: IApproval,   
    group2: IApproval,
    group3: IApproval,
    group4: IApproval,
    group5: IApproval,
    group6: IApproval,
    group7: IApproval,
    group8: IApproval,
    group9: IApproval,
    group10: IApproval,

}
export class Approval implements IApproval{
    groupName: string;
    users: [{name:string,uid:string}];
    constructor(){
        this.groupName=''; 
        this.users=[{name:'',uid:''}];
    }

}
export class Location implements ILocation{
    name: string;
    group1: Approval;  
    group2: Approval;
    group3: Approval;
    group4: Approval;
    group5: Approval;
    group6: Approval;
    group7: Approval;
    group8: Approval;
    group9: Approval;
    group10: Approval;

    constructor(){
        this.name=''; 
        this.group1 = new Approval();   
        this.group2 = new Approval();
        this.group3 = new Approval();
        this.group4 = new Approval();
        this.group5 = new Approval();
        this.group6 = new Approval();
        this.group7 = new Approval();
        this.group8 = new Approval();
        this.group9 = new Approval();
        this.group10 = new Approval();

        

    }

}