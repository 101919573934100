<h2>Manager Approvals</h2>
<form *ngIf='signatureGroups.length !==0'>
    <div class="col-sm d-flex flex-column flex-wrap">
        <div *ngFor='let group of signatureGroups;' class='col'>
            <div *ngIf='managers[group["groupName"]]["groupName"].endsWith("Manager")'>
                <label>
                    {{group['groupName'] }} Signatures:
                </label>
                <div  *ngIf="activeDoc.managers[group['groupName']]['signature'] == '' || activeDoc.managers[group['groupName']]['approved'] == false">
                    <input type="checkbox" [id]="group['groupName']+'_approve'" [name]="group['groupName']+'_approve'" 
                    [(ngModel)]="activeDocCopy.managers[group['groupName']]['approved']" 
                     [disabled]="activeDocCopy.managers[group['groupName']]['signature'] !== firebaseName">
                    <label [for]="group['groupName']+'_approve'">Approve</label><br>

                    <!-- <input type="radio" id="group['groupName']+'_approve'" name="gender" value="male"><label for="male">Male</label><br> -->
                    <select [name]="group['groupName']" [id]="group['groupName']+'_sel'" class='form-control'
                        [(ngModel)]="activeDocCopy.managers[group['groupName']]['signature']">
                        <option *ngFor="let user of group['users']"  [ngValue]="user.name"
                            [disabled]="user.uid !== firebaseUID">{{user.name}}</option>
                    </select>
                    
                    <input required [name]="group['groupName']+'_comment'" class="form-control"
                        [id]="group['groupName']+'_comment'" type="text"
                        [(ngModel)]="activeDocCopy.managers[group['groupName']]['comment']"
                        [disabled]="activeDocCopy.managers[group['groupName']]['signature'] !== firebaseName">

                        {{activeDoc.managers[group['groupName']]['timeStamp']|date:'short'}}  
                </div>
                <div
                    class='card'
                    *ngIf="activeDoc.managers[group['groupName']]['signature'] != '' && activeDoc.managers[group['groupName']]['approved'] == true">
                    <div class='card-title'>{{activeDoc.managers[group['groupName']]['signature']}}</div>
                    <div class="card-body"><br> --- Comments --- <br>
                        {{activeDoc.managers[group['groupName']]['comment']}} <br>
                        {{activeDoc.managers[group['groupName']]['timeStamp']|date:'short'}}</div>


                </div>
            </div>
        </div>
        <div class='col'>
            <button class='btn btn-primary' 
             [disabled]="activeDoc.formState =='approved' "
              (click)="onSubmit()">Submit</button>
        </div>
    </div>




</form>