import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ILocation, Location } from '../models/location'
import { AuthService } from './auth.service';

const locationCollection = 'location'
@Injectable({
  providedIn: 'root'
})

// name: string;
// group1: Approval;  
// group2: Approval;
// group3: Approval;
// group4: Approval;
// group5: Approval;
// group6: Approval;


export class LocationService {
  fbUserId: string;
  authLocations: any;
  userLocations:any;
  constructor(private firestore: AngularFirestore,public auth: AuthService) { 
    this.authLocations = new Array(Location);
    this.auth.user$.subscribe(user=>{
      this.fbUserId = user.uid;
    })
  }
  // groupName: string;
  // users: [{name:string,uid:string}];
  // constructor(){
  //     this.groupName=''; 
  //     this.users=[{name:'',uid:''}];
  // }
  
  private convertLocationToFirebase(loc: Location) {

    // name: string;
    // group1: Approval;  
    // group2: Approval;
    // group3: Approval;
    // group4: Approval;
    // group5: Approval;
    // group6: Approval;
    // groupName: string;
    // users: [{name:string,uid:string}];
    // constructor(){
    //     this.groupName=''; 
    //     this.users=[{name:'',uid:''}];
    // }
    // formState: form.formState,
    //   request: {
    //     requestorFirstName: form.request.requestorFirstName,
    //     requestorLastName: form.request.requestorLastName,
    //     timeStamp: form.request.timeStamp,
    //     rfmNumber: form.request.rfmNumber,
    //     wrkOrder: form.request.wrkOrder,
    //     processArea: form.request.processArea,
    //     duration: form.request.duration,
    //     safety: form.request.safety,
    //     request: form.request.request,
    //     removeTimeStamp: form.request.removeTimeStamp,

    //   },
    //   managers: {
    //     manager1: 
    let obj = {
      name: loc.name,
      group1: {
        groupName: loc.group1.groupName,
        users: loc.group1.users
      },
      group2: {
        groupName: loc.group2.groupName,
        users: loc.group2.users
      },
      group3: {
        groupName: loc.group3.groupName,
        users: loc.group3.users
      },
      group4: {
        groupName: loc.group4.groupName,
        users: loc.group4.users
      },
      group5: {
        groupName: loc.group5.groupName,
        users: loc.group5.users
      },
      group6: {
        groupName: loc.group6.groupName,
        users: loc.group6.users
      },
      group7: {
        groupName: loc.group7.groupName,
        users: loc.group7.users
      },
      group8: {
        groupName: loc.group8.groupName,
        users: loc.group8.users
      },
      group9: {
        groupName: loc.group9.groupName,
        users: loc.group9.users
      },
      group10: {
        groupName: loc.group10.groupName,
        users: loc.group10.users
      },
    };



    return obj;

  }

  getLocationsForUser( uid: string) {
  this.firestore.collection(locationCollection).snapshotChanges() 
  .subscribe(data => {
    let locations = data.map(e => {
      return {
        id: e.payload.doc.id,
        ...e.payload.doc.data() as ILocation
      };//as any;
    })
    //after location service returns locations, run through locations array
    for( let location of locations)
    {
      //go through the properties of the current location
        for(let prop in location)
         {// if the object has the property
          if (Object.prototype.hasOwnProperty.call(location, prop))
            if(prop.startsWith('group')){  // and property is one of the groups
             if( location[prop]['groupName'] == 'requestor') // and the group is the requestor group
                 for( let u of location[prop]['users'] ) // go throught the users array looking for the current logged in user
                     if(u['uid'] == this.fbUserId)
                         this.authLocations.push(location) // soore location in authroized locations for the user.
                        
                
               // console.log(location[prop]['groupName'])
            }
                
         }
          
    }
   // console.log(this.authLocations);
   return this.authLocations; 
  });
      
  }


  

  getLocations() {
    return this.firestore.collection(locationCollection).snapshotChanges();
  }
  createLocation(location: Location) {


    return this.firestore.collection('location').add(this.convertLocationToFirebase(location));

    // return this.firestore.collection(locationCollection).add(location);//.toFireStore());
  }
}
