
<nav class="navbar navbar-dark  bg-dark flex-md-nowrap p-0 shadow">
  <h1 ><a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#"></a></h1>
  <button class="navbar-toggler  d-md-none collapsed" 
  type="button" data-toggle="collapse" data-target="#sidebarMenu"
   aria-controls="sidebarMenu" aria-expanded="true" aria-label="Toggle navigation"
   (click)='toggleNavbar()'>
    <span class="navbar-toggler-icon"></span>
  </button>
 
  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="sidebarMenu">
    <ul *ngIf="auth.user$ | async as user;" class="navbar-nav d-flex px-3 justify-content-around" [ngClass]="scrWidth &lt;=767 ? 'flex-column':'flex-row' ">
      <li  *ngIf="this.appUser.isAdmin == true" class="nav-item">
        <a class="nav-link active" routerLink='/admin'>
          <span data-feather="admin"></span>
          Admin <span class="sr-only"></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/formlist" [queryParams] = '{ user: user.displayName}'
        [user] =  user>
          <span data-feather="file"></span>
          Forms List
        </a>
      </li>
      <li class="nav-item" >
          <a class="nav-link" routerLink="/form" [user] = user [queryParams] = '{ user: user.displayName}'>
            <span data-feather="shopping-cart"></span>
             Create Form
          </a>
        </li>
      <li class="nav-item text-nowrap">
        <a  class="nav-link" href="#" (click)="logout()">Sign out</a>
      </li>
    </ul>
  </div>
 
</nav>

<div class="container-fluid">
  <div class="row">
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Dashboard</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <!-- <div class="btn-group mr-2">
            <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
          </div> -->
          <!-- <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button> -->
        </div>
      </div>
      <div *ngIf="auth.user$ | async as user; else showLogin">
        <h3>Hello {{ user.displayName }}!</h3>
        <router-outlet></router-outlet>
    </div>
    <ng-template #showLogin>
      <p>Please login.</p>
      <label for userEmail>User Name</label>
      <input [(ngModel)]='userEmail' name='userEmail' class="form-control" id='userEmail' type="text" >
      <label for userPass>Password</label>
      <input  [(ngModel)]='userPass' name='userPass' class="form-control" id='userPass' type="password" (keyup.enter)="onPasswordEnter($event)" >
      <button (click)="login()">Login</button>
      <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    </ng-template>
    


