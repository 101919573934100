
 
<div class="table-responsive">
    <!-- <table class="table table-striped table-sm"> -->
      <table class="table  table-sm">
      <thead>
        <tr>
          <td><button class='btn btn-primary' (click)='setNewUser();' style="padding:1em; margin:1em;" >Create User</button></td>
          <td><button class='btn btn-primary' (click)='setEditUser();' style="padding:1em; margin:1em;" >Edit Users</button></td>
          <td><button class='btn btn-primary' (click)='setLocation();' style="padding:1em; margin:1em;" >Locations</button></td>
        </tr>
        
        <!-- <tr >
          <button class='btn btn-primary' (click)='updateUser()' style="padding:1em; margin:1em;" >edit</button>
          <button class='btn btn-primary' (click)='deleteUser()'style="padding:1em; margin:1em;" >delete</button>
        </tr> -->
        <!-- <tr>
          <th (click)='sortByDate()' *ngIf="dateAssend ==true">Request Date&#9650;</th>
          <th (click)='sortByDate()' *ngIf="dateAssend ==false">Request Date&#9660;</th>
          <th >Rfm Number</th>
          <th (click)='sortByStatus()' *ngIf="statusAssend ==true" >Status&#9650;</th>
          <th (click)='sortByStatus()' *ngIf="statusAssend ==false" >Status&#9660;</th>
          <th (click)='sortByName()' *ngIf="nameAssend ==true">Name&#9650;</th>
          <th (click)='sortByName()' *ngIf="nameAssend ==false">Name&#9660;</th>
          <th (click)='sortByArea()' *ngIf="areaAssend ==true">Area&#9650;</th>
          <th (click)='sortByArea()' *ngIf="areaAssend ==false">Area&#9660;</th>
          <th>Request</th>
        </tr> -->
      </thead>
      <!-- <tbody>
        
        
        
        <tr *ngFor="let user of users" (click)= "setSelectedId(user.id)" [ngClass] = "{'active': selectedId === user.id}">
        
          
          
          <td>{{user.name}}</td>
          <td>{{user.email}}</td>
          <td >
            <tr *ngFor="let loc of userLocationRoles">
                <span>{{loc.name}}</span>
            </tr>
            
          </td>
          
         
          
        </tr>
      </tbody> -->
      
    </table>
  </div>

<app-edit-user *ngIf="editUserToggle === true" (resetAdminSelects)="resetAdmin();"></app-edit-user>
<app-new-user *ngIf="createUserToggle === true"></app-new-user>
<app-location *ngIf="locationToggle === true"></app-location>