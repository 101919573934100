interface IEmailNotification{
    rfmNumber: string,
    rfmDetails: string,
    userName: string,
    userEmail: string,
   
}
export class EmailNotification implements IEmailNotification{
    rfmNumber: string;
    rfmDetails: string;
    userName: string;
    userEmail: string;
    constructor(){
        this.rfmNumber ='';
        this.rfmDetails='';
        this.userName='';
        this.userName='';
    }
    
}