import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from '../services/form.service'
import { LocationService } from '../services/location.service';
import { Rfm, Manager, Engineer, Request } from '../models/rfm'
import { ILocation, Location } from '../models/location'
import { iAppUser, AppUser } from '../models/app-user';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { observable } from 'rxjs';



@Component({
  selector: 'request-mod',
  templateUrl: './request-mod.component.html',
  styleUrls: ['./request-mod.component.css']
})
export class RequestModComponent {
  formData: Rfm;
  forms: any[];
  locations: Location[];
  authLocations: any;
  users: AppUser[];
  @Input('activeDoc') activeDoc: Rfm;

  rfmNum: string;
  area: string;
  areas = [];
  workOrder: string;
  tempPerm: string;
  affected: boolean;
  requestDetails: string;
  removalDate: string;
  removalTime: string;
  userAuth = true;
  firebaseUID = '';

  @Input('user') user: AppUser;

  @Output('request') request = new EventEmitter();
  constructor(private formservice: FormService, private locationService: LocationService, private userService: UserService, public auth: AuthService) {
    this.formData = new Rfm(); // DONT KNOW IF WE WILL NEED THIS ON THIS VIEW. UNSURE IF WE WILL LOAD HER OR ON ITS OWN COMPONENT
    // THEN ROUTE HERE AND SET ACTIVEDOC TO SELECTED ONE
    this.activeDoc = new Rfm();
    this.user = new AppUser();
    this.authLocations = new Array();
    this.auth.user$.subscribe(user => {
      this.user.name = user.displayName;
      this.firebaseUID = user.uid;
      //console.log(this.user.isAdmin);
      //this.authLocations = this.locationService.getLocations()
      // console.log(user);
    });
  };
  ngOnInit(): void {

    this.formservice.getForms().subscribe(data => {
      this.forms = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Rfm
        };//as any;
      })
    });

    /********************* WORKING ************************** */
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as ILocation
        };//as any;
      })
      //after location service returns locations, run through locations array
 
        for (let location of this.locations) {
          //go through the properties of the current location
          for (let prop in location) {// if the object has the property
            if (Object.prototype.hasOwnProperty.call(location, prop))
              if (prop.startsWith('group')) {  // and property is one of the groups
                if (location[prop]['groupName'] == 'Requester' || this.activeDoc.formState == 'submitted') // and the group is the requestor group
                  for (let u of location[prop]['users']) // go throught the users array looking for the current logged in user
                    if (u['uid'] == this.firebaseUID)
                      this.authLocations.push(location);
                // soore location in authroized locations for the user.

                //CHANGES FOR GIT
                // console.log(location[prop]['groupName'])
              }

          }
        }
      
     
     // console.log(this.authLocations);

    });
    /**************************************************WORKING *******************************/


  }
  onShowLocation() {

    //   for(let loc of this.locations){
    //  if(loc.name == 'Pack House' )
    //  console.log(loc.group2.groupName)
    //    for(let user of loc.group2.users){
    //     console.log(user.name)
    //    }    
    //   }
    // console.log(this.locations)
    const a = new Location();
    a.name = 'Quary';
    a.group1.groupName = 'requestor'
    a.group1.users.pop();
    a.group1.users.push({ name: 'Chris Smith', uid: 'jjjfdk' })
    a.group1.users.push({ name: 'Charles Terrell', uid: 'j1234' })

    a.group2.groupName = 'maintenance';
    a.group2.users.pop();
    a.group2.users.push({ name: 'Derek McKentyre', uid: 'jjjfdk' })
    a.group2.users.push({ name: 'Nick Trout', uid: 'j1234' })

    a.group3.groupName = 'electrical';
    a.group3.users.pop();
    a.group3.users.push({ name: 'Rodney Preston', uid: 'jjjfdk' })
    a.group3.users.push({ name: 'Billy Madison', uid: 'j1234' })

    a.group4.groupName = 'engineer';
    a.group4.users.pop();
    a.group4.users.push({ name: 'Dan Brown', uid: 'jjjfdk' })
    a.group4.users.push({ name: 'Sam Foster', uid: 'j1234' })

    a.group5.groupName = 'production';
    a.group5.users.pop();
    a.group5.users.push({ name: 'Shawn Wythe', uid: 'jjjfdk' })
    a.group5.users.push({ name: 'Chris Davison', uid: 'j1234' })

    a.group6.groupName = 'quality';
    a.group6.users.pop();
    a.group6.users.push({ name: 'Paul Cunningham', uid: 'jjjfdk' })
    a.group6.users.push({ name: 'Nick Ewing', uid: 'j1234' })

    this.locationService.createLocation(a).then(e => {
      console.log(e.id)
    });

  }
  onRequest() {
    

    //NEW CODE 20200810 DLB

    this.activeDoc.request.timeStamp = new Date().toString();
    /// SET FORMSTATE TO DESIRED STATE
    /****************************** NEED TO ADD CODE BELOW IN THE .THEN CLAUSE TO MAKE SURE WAS INSERTED, ELSE CHANGE NOT SUBMITTED */
    this.activeDoc.formState = 'submitted';

    if (this.removalDate != null && this.removalTime != null && this.activeDoc.request.duration == "temporary")
      this.activeDoc.request.removeTimeStamp = new Date(this.removalDate + " " + this.removalTime).toISOString()
    this.activeDoc.request.requestorName = this.user.name;
    for(let loc of this.authLocations) {
      for (let prop in loc) {// if the object has the property
          
        if (Object.prototype.hasOwnProperty.call(loc, prop))
          if (prop.startsWith('group')) {  // and property is one of the groups
            //  if( location[prop]['groupName'] == 'Requester') // and the group is the requestor group
            //      for( let u of location[prop]['users'] ) // go throught the users array looking for the current logged in user
            //          if(u['uid'] == this.fbUserId)
            //              this.authLocations.push(location) // soore location in authroized locations for the user.

            if (loc[prop]['groupName'].endsWith('Manager')) {
              let prtyname = loc[prop]['groupName'];
              let obj = new Manager();
              obj.approved = false;
              obj.comment = '';
              obj.signature = '';
              obj.timeStamp ='';
              //this.activeDoc.managers.push(obj);
              this.activeDoc.managers[loc[prop]['groupName']] = obj;
              
              
            }
            // console.log(location[prop]['groupName'])

          }

      }
    }
    let eng = new Engineer;
    this.activeDoc.engineer = eng;
    

    this.formservice.createForm(this.activeDoc).then(e => {

      // COPYING SUBMITTED TO ACTIVE DOC ONLY AFTER THE RECORD WAS SUCESSFULLY PUT IN THE DATBASE DURING THIS CALL BACK
      // COPYING TO DOC MAY NOT BE NECESSARY
      if (this.activeDoc.request.rfmNumber != e.id) {
        this.activeDoc.request.rfmNumber = e.id;
        this.formservice.setId(this.activeDoc)

      }

    })
  }







}
