<h5>System Request for Modification - {{this.activeDoc.formState}}</h5>
<form #form='ngForm' >
   Request Date Time {{activeDoc.request.timeStamp|date:'short'}}
    <div class='row' >
      <div class="col" >
        <label for="requestor">Requestor:</label>
       <input required [(ngModel)]='activeDoc.request.requestorName' name='requestor' class="form-control" id='requestor' type="text" disabled/>
       <label for="rfm">RFM:</label>
       <input required [(ngModel)]='activeDoc.request.rfmNumber' name='rfmNum' class="form-control" [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null'id='rfm' type="text" disabled/>
       <label for="area">Process Area:</label>
       <select required [(ngModel)]='activeDoc.request.processArea' name="area" id="area" class='form-control' [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null' [disabled]="activeDoc.formState != '' ">
        <option *ngIf='activeDoc.request.processArea'> {{activeDoc.request.processArea}}</option>
        <option *ngFor='let area of authLocations' value="{{area.name}}">{{area.name}}</option>
       </select> 
      </div>
      <div class="col">
        <label for="work-order">Work Order:</label>
        <input required [(ngModel)]='activeDoc.request.wrkOrder' name='workOrder' id='work-order' class="form-control" [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null'type="text"  [disabled]="activeDoc.formState !=''"/>
            <label for="temp-perm">Temporary or Permanent: </label>
            <select required [(ngModel)]='activeDoc.request.duration' name='tempPerm' id="temp-perm" class='form-control' [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null' [disabled]="activeDoc.formState !=''">
              <option></option>
              <option value="temporary">Temporary</option>
              <option value="permanent">Permanent</option>
            </select>

            <label for="affected">Special Considerations for Safety and Operations: </label>
            <textarea required [(ngModel)]='activeDoc.request.safety' name='safety' id="safety" class='form-control' [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null' [disabled]="activeDoc.formState !=''"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <label for="request-details">Request Details:</label>
          <textarea  required [(ngModel)]='activeDoc.request.request' name="requestDetails" rows='5' id="request-details" class="form-control" [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null'  [disabled]="activeDoc.formState !=''"></textarea>
      </div>

    </div>
    <div [hidden]= "activeDoc.formState !='' || activeDoc.request.duration == 'permanent'" class="row">
      <div class="col">
        <label for="removal-date">Removal Date:</label>
        <input required [(ngModel)]='removalDate' name='removalDate' id='removal-date' class='form-control' [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null' type="date"  [disabled]="activeDoc.formState !=''"/>
      </div>
      <div class="col">
        <label for="removal-time">Removal Time:</label>
        <input required [(ngModel)]='removalTime' name='removalTime' id='removal-time' type="time" class='form-control' [ngClass]='activeDoc.request.timeStamp != "" ? "card" : null' [disabled]="activeDoc.formState !=''">
      </div>
    </div>
    <div [hidden]="activeDoc.formState != 'submitted' || activeDoc.request.duration == 'permanent'">
        <h3>Removal Date/Time {{activeDoc.request.removeTimeStamp|date:'short'}}</h3>
    </div>
    <button class='btn btn-primary' (click)='onPostRfm();' [disabled]="rfmDisable">Submit</button>

    <input type="checkbox" [id]="activeDoc.request.rfmNumber+'_review'" [name]="activeDoc.request.rfmNumber+'_review'"
        [(ngModel)]="reviewed"
        [disabled]="activeDoc.request.requestorName !== firebaseName || activeDoc.formState != 'implemented'">
    <label for="activeDoc.request.rfmNumber+'_review'">Verfiy Function</label>

</form>


<h2 *ngIf='activeDoc.formState!==""'>Manager Approvals</h2>
<form *ngIf='activeDoc.formState!==""'>
    <div class="col-sm d-flex flex-column flex-wrap">
        <div *ngFor='let group of signatureGroups;' class='col'>
            <div *ngIf='managers[group["groupName"]]["groupName"].endsWith("Manager")'>
                <label>
                    {{group['groupName'] }} Signatures:
                </label>
                <div
                    *ngIf="activeDoc.managers[group['groupName']]['signature'] == '' || activeDoc.managers[group['groupName']]['approved'] == false">
                    <input type="checkbox" [id]="group['groupName']+'_approve'" [name]="group['groupName']+'_approve'"
                        [(ngModel)]="activeDocCopy.managers[group['groupName']]['approved']"
                        [disabled]="activeDocCopy.managers[group['groupName']]['signature'] !== firebaseName">
                    <label [for]="group['groupName']+'_approve'">Approve</label><br>

                    <!-- <input type="radio" id="group['groupName']+'_approve'" name="gender" value="male"><label for="male">Male</label><br> -->
                    <select [name]="group['groupName']" [id]="group['groupName']+'_sel'" class='form-control'
                        [(ngModel)]="activeDocCopy.managers[group['groupName']]['signature']">
                        <option *ngFor="let user of group['users']" [ngValue]="user.name"
                            [disabled]="user.uid !== firebaseUID">{{user.name}}</option>
                    </select>

                    <input required [name]="group['groupName']+'_comment'" class="form-control"
                        [id]="group['groupName']+'_comment'" type="text"
                        [(ngModel)]="activeDocCopy.managers[group['groupName']]['comment']"
                        [disabled]="activeDocCopy.managers[group['groupName']]['signature'] !== firebaseName">

                    {{activeDoc.managers[group['groupName']]['timeStamp']|date:'short'}}
                </div>
                <div class='card'
                    *ngIf="activeDoc.managers[group['groupName']]['signature'] != '' && activeDoc.managers[group['groupName']]['approved'] == true">
                    <div class='card-title'>{{activeDoc.managers[group['groupName']]['signature']}}</div>
                    <div class="card-body"> --- Comments --- <br>
                        {{activeDoc.managers[group['groupName']]['comment']}} <br>
                        {{activeDoc.managers[group['groupName']]['timeStamp']|date:'short'}}</div>


                </div>
            </div>
        </div>
        <div class='col'>
            <button class='btn btn-primary' [disabled]="managerDisable " (click)="onPostApproval()">Submit</button>
        </div>
    </div>




</form>

<h2 *ngIf='activeDoc.formState!==""'>System Engineer only below</h2>

<form *ngIf='activeDoc.formState!==""'>
    {{activeDocCopy.engineer.engineerName}} - {{activeDocCopy.engineer.timeStamp | date:'short'}}

    <div class="row no-gutters">

        <div class="col">
            <label for="change-details">Details of Change:</label>
            <textarea [(ngModel)]="activeDocCopy.engineer.changeTest" name='changeDetails' id='change-details'
                class='form-control' [ngClass]='activeDoc.engineer.timeStamp != "" ? "card" : null'
                [disabled]="!authorizedEngineer || activeDoc.formState == 'implemented'"></textarea>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col">
            <label for="drawings-modified">Drawings Modified:</label>
            <textarea id='drawings-modified' [(ngModel)]='activeDocCopy.engineer.drawingChanges' name='drawingsModified'
                class='form-control' [ngClass]='activeDoc.engineer.timeStamp != "" ? "card" : null'
                [disabled]="!authorizedEngineer || activeDoc.formState == 'implemented'"></textarea>
        </div>
    </div>
    <!-- <div class="row no-gutters">
        <div class="col">
            <div class="custom-file">
                <input type="file" [(ngModel)]='fileAttachments' name='fileAttachments' class="custom-file-input"
                    id="customFile" [disabled]="!authorizedEngineer || activeDoc.formState == 'implemented'"
                    [ngClass]='activeDoc.engineer.timeStamp != "" ? "card" : null'>
                <label class="custom-file-label" for="customFile">Attach relevant files</label>
            </div>
        </div>
    </div> -->
    <button class="btn btn-primary" (click)='onPostAction()' [disabled]="engineerDisable">Submit</button>

    <input type='checkbox' id='implemented' [(ngModel)]='implemented' name="implemented-check"
        [disabled]="!authorizedEngineer || activeDoc.formState == 'implemented'">
    <label for='implemented'>Implementation Complete</label>

</form>


<!-- <request-mod [activeDoc]='this.activeDoc' ></request-mod> -->
<!-- <app-approval [activeDoc]='this.activeDoc' ></app-approval>
 <app-action [activeDoc]='this.activeDoc' ></app-action> -->