

<h2>System Request for Modification</h2>
<form #form='ngForm'>
   Request Date Time {{activeDoc.request.timeStamp|date:'short'}}
    <div class='row'>
      <div class="col">
        <label for="requestor">Requestor:</label>
       <input required [(ngModel)]='activeDoc.request.requestorName' name='requestor' class="form-control" id='requestor' type="text" disabled/>
       <label for="rfm">RFM:</label>
       <input required [(ngModel)]='activeDoc.request.rfmNumber' name='rfmNum' class="form-control" id='rfm' type="text" disabled/>
       <label for="area">Process Area:</label>
       <select required [(ngModel)]='activeDoc.request.processArea' name="area" id="area" class='form-control'  [disabled]="activeDoc.formState != '' ">
        <option *ngIf='activeDoc.request.processArea'> {{activeDoc.request.processArea}}</option>
        <option *ngFor='let area of authLocations' value="{{area.name}}">{{area.name}}</option>
       </select> 
      </div>
      <div class="col">
        <label for="work-order">Work Order:</label>
        <input required [(ngModel)]='activeDoc.request.wrkOrder' name='workOrder' id='work-order' class="form-control" type="text"  [disabled]="activeDoc.formState !=''"/>
            <label for="temp-perm">Temporary or Permanent: </label>
            <select required [(ngModel)]='activeDoc.request.duration' name='tempPerm' id="temp-perm" class='form-control'  [disabled]="activeDoc.formState !=''">
              <option></option>
              <option value="temporary">Temporary</option>
              <option value="permanent">Permanent</option>
            </select>
    
            <label for="affected">Special Considerations for Safety and Operations: </label>
            <textarea required [(ngModel)]='activeDoc.request.safety' name='safety' id="safety" class='form-control'  [disabled]="activeDoc.formState !=''"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <label for="request-details">Request Details:</label>
          <textarea  required [(ngModel)]='activeDoc.request.request' name="requestDetails" rows='5' id="request-details" class="form-control"  [disabled]="activeDoc.formState !=''"></textarea>
      </div>

    </div>
    <div [hidden]= "activeDoc.formState !='' || activeDoc.request.duration == 'permanent'" class="row">
      <div class="col">
        <label for="removal-date">Removal Date:</label>
        <input required [(ngModel)]='removalDate' name='removalDate' id='removal-date' class='form-control' type="date"  [disabled]="activeDoc.formState !=''"/>
      </div>
      <div class="col">
        <label for="removal-time">Removal Time:</label>
        <input required [(ngModel)]='removalTime' name='removalTime' id='removal-time' type="time" class='form-control'  [disabled]="activeDoc.formState !=''">
      </div>
    </div>
    <div [hidden]= "activeDoc.formState != 'submitted' || activeDoc.request.duration == 'permanent'">
      <h3>Removal Date/Time {{activeDoc.request.removeTimeStamp|date:'short'}}</h3>
    </div>
    <button class='btn btn-primary' (click)='onRequest();'  [disabled]="activeDoc.formState !='' ">Submit</button>
</form> 



