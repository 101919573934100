import { Component, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Rfm, Manager, Engineer, Request } from './models/rfm'
import { FormService } from './services/form.service'
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AuthService } from './services/auth.service';
import { Router, RouterOutlet, ActivationStart, } from '@angular/router';

import { RequestModComponent } from './request-mod/request-mod.component'
import { UserService } from './services/user.service';
import { iAppUser, AppUser } from './models/app-user';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'RFM';
  formData: Rfm;
  forms: any[];
  activeDoc: Rfm;

  isRequest = true;
  isApprove = true;
  isAction = true;

  requestObj: RequestModComponent;
  approveObj;
  actionObj;
  readDocs: any[];
  userEmail: string;
  userPass: string;
  appUser: AppUser;
  fbUserId = '';
  scrWidth: any;
  navbarOpen = true;
  errorMessage = '';

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  constructor(private formservice: FormService, public auth: AuthService, private userService: UserService, private router: Router /*public auth: AngularFireAuth*/) {
    this.formData = new Rfm();
    this.readDocs = [];
    this.userEmail = '';
    this.userPass = '';
    this.appUser = new AppUser();
  

    auth.user$.subscribe(user => {
      if (user) {
        // console.log(user);
        // this.user =  this.userService.get(user.uid)
        userService.save(user);
        userService.getUserDetails(user.uid).subscribe(dat=>{
          this.appUser = dat;
        })
       // this.user = userService.getUserDetails(user.uid)

        let returnUrl = localStorage.getItem('returnUrl');
        //router.navigateByUrl(returnUrl);
        router.navigateByUrl('/formlist');
      }

    });
    // this.auth.user$.subscribe(user=>{
    //   this.user.name = user.displayName;
    //   this.fbUserId = user.uid;
    //   this.user.email = user.email;
    //   console.log(this.user);
    //   //this.authLocations = this.locationService.getLocations()
    //  // console.log(user);
    // });
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 767) {
      this.navbarOpen = false;
    }
    else if (this.scrWidth >= 768) {
      this.navbarOpen = true;
    }
  }
  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === "admin-panel")
        this.outlet.deactivate();
    });
  }

  onPasswordEnter(event) {

    this.auth.loginEmailPass(this.userEmail, this.userPass);

  }

  login() {
    // this.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.auth.loginEmailPass(this.userEmail, this.userPass)
    .then(rslt=>{

    })
    .catch(error=>{
      this.errorMessage = error;
    })
  

    //  this.auth.signInWithEmailAndPassword(this.userEmail,this.userPass).then(rslt=>{
    //  console.log(rslt);
    //  });

  }
  logout() {
    this.auth.logout();
  }






  signatureHandler(signatureForm) {
    this.approveObj = signatureForm;
    console.log(this.approveObj);
  }
  actionHandler(actionForm) {
    this.actionObj = actionForm;
    console.log(this.actionObj);
  }




}


