<div class='table-bordered table-responsive w-auto'>
    <div class="table-responsive">
        <!-- <table class="table table-striped table-sm"> -->
          <table class="table  table-sm">
          <thead>
           
            
            <!-- <tr >
              <button class='btn btn-primary' (click)='updateUser()' style="padding:1em; margin:1em;" >edit</button>
              <button class='btn btn-primary' (click)='deleteUser()'style="padding:1em; margin:1em;" >delete</button>
            </tr> -->
            <!-- <tr>
              <th (click)='sortByDate()' *ngIf="dateAssend ==true">Request Date&#9650;</th>
              <th (click)='sortByDate()' *ngIf="dateAssend ==false">Request Date&#9660;</th>
              <th >Rfm Number</th>
              <th (click)='sortByStatus()' *ngIf="statusAssend ==true" >Status&#9650;</th>
              <th (click)='sortByStatus()' *ngIf="statusAssend ==false" >Status&#9660;</th>
              <th (click)='sortByName()' *ngIf="nameAssend ==true">Name&#9650;</th>
              <th (click)='sortByName()' *ngIf="nameAssend ==false">Name&#9660;</th>
              <th (click)='sortByArea()' *ngIf="areaAssend ==true">Area&#9650;</th>
              <th (click)='sortByArea()' *ngIf="areaAssend ==false">Area&#9660;</th>
              <th>Request</th>
            </tr> -->
          </thead>
          <tbody>
            
            <!-- <tr *ngIf="activeDoc" >{{activeDoc.request.request}}</tr>  -->
            
            <tr>
              
              <!-- <td  (click)="setSelectedId($event)">{{form.request.rfmNumber}}</td>   -->
              <!-- <td>{{user.name}}</td>
              <td>{{user.email}}</td> -->
              <td >
                <!-- <tr *ngFor="let loc of userLocationRoles">
                    <span>{{loc.name}}</span>
                </tr> -->
                
              </td>
              
             
              
            </tr>
          </tbody>
          
        </table>
      </div>

<table class="table  table-sm"  >
    <th >
        <tr>
        <td>User Name</td>
        <td>User Email</td>
        <td>User Password</td>    
        </tr>
    </th>
    <tr>
        <td>
           <label for="username">Enter User Name: </label>
        <input type='text' [(ngModel)]="userName" name="nUser.name" 
        (click)='isDirty=true;'/> 
        </td>
        <td>
            <label for="email">Enter email address: </label>
        <input type='text' [(ngModel)]="email" name="nUser.email" 
        (click)='isDirty=true;'/>
        </td>
        <td>
            <label for="password">Enter password: </label>
        <input type='text' [(ngModel)]="password" name="password" 
        (click)='isDirty=true;'/>
        </td>
        <td>
            <label for="isadmin">Admin </label>
        <input type="checkbox" name="isadmin" [(ngModel)]="isAdmin" value="nUser.isAdmin" 
        (click)='isDirty=true;'/>
        </td>
       
    </tr>
    
    
</table>
    <table class="table  table-sm" >
        <th>
            <tr>
                <th>Area Roles</th>
            </tr>
        </th>
        <tbody>
            <tr *ngFor="let location of areas">
                <td>{{location.groupName}}</td>

                <!-- <input type="checkbox" name="{{gr.Role}}" id="{{gr.Role}}"/>{{gr.Role}} -->
<!-- [disabled] = 'isDirty' -->
                <td *ngFor="let gr of location.groups">  
                    <input type="checkbox"  name="{{gr.Role}}" id="{{gr.Role}}" [(ngModel)]="gr[gr.Role]" 
                    (click)='isDirty=true;' />
                    {{gr.Role}}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <div *ngIf="updating">
                <span *ngIf='updating' class ='spinner-border spinner-border-sm' role='status' aria-hidden="true"></span>
            </div>
            <div *ngIf="isDirty">
             <button class='btn btn-primary' (click)='createUser();'>
                
                create
            </button>  
            

            </div>
            
        </tfoot> 
    </table>
   
    <div *ngIf='errorUpdating' class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img src="..." class="rounded mr-2" alt="...">
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted">error</small>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          Error updating user, Please retry.
        </div>
      </div>

</div>
