
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { EmailNotification } from '../models/notification';
import { not } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private firestore: AngularFirestore) { }
  createNotification(notification: EmailNotification ) {
     console.log(notification);
    //  rfmNumber: string;
    // rfmDetails: string;
    // userName: string;
    // userEmail: string;
    return this.firestore.collection('notification').add({
      rfmNumber: notification.rfmNumber,
      rfmDetails: notification.rfmDetails,
      userName : notification.userName,
      userEmail: notification.userEmail
    });//.toFireStore());
  }
}
