

<table>
        <th>
            <tr>
                <th>Area Roles</th>
            </tr>
        </th>
        <tbody>
            <tr *ngFor="let location of areas">
             <td>{{location.groupName}}</td> 

             <!-- <input type="checkbox" name="{{gr.Role}}" id="{{gr.Role}}"/>{{gr.Role}} -->
             
             <td *ngFor="let gr of location.groups"><input type="checkbox" name="{{gr.Role}}" id="{{gr.Role}}"/>{{gr.Role}}</td> 
            
            
            

            </tr>
        </tbody>
    </table>


    <!-- LEAVING THIS EXAMPLE USING as DROP DOWN, probably use as filter for RFM LIST at some point
Ignore for now.-->
<!-- <h4>Ignore this code. Keeping for example only</h4>
<div>
    <label for="location">Location:</label>

    <select name="location" id="location" [(ngModel)]="activeLocation" (change)="onLocChange()">
        <option  *ngFor="let location of locations" [ngValue]="location.name" > {{location.name}}</option> -->

        
<!-- SELECT EXAMPLE AFTER SELECT FILLS OPTIONS With roles of location only-->    
<!-- </select>
<label for="groups">groups</label>
<select name = "groups" id="groups" >
    <option *ngFor="let loc of selLocation">{{loc}}</option> -->
 <!-- Generate checkbox for a location--> 
<!-- </select>
<table>
    <tbody>
       
            <tr *ngFor="let loc of selLocation" >
               <td> <input type="checkbox" name="{{loc}}" id="{{loc}}"/>{{loc}}</td>
            </tr>
     </tbody>  
   
</table>
<h4>End of example stuff</h4> -->
<!-- END OF EXAMPLE CODE-->    

<!-- </div> -->