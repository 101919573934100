import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Rfm } from '../models/rfm'

import { stringify } from '@angular/compiler/src/util';
import { FormsModule } from '@angular/forms';
const rfmCollection = 'RFM-APP'
@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private firestore: AngularFirestore) { }
  getForms() {
    return this.firestore.collection(rfmCollection).snapshotChanges();
  }
  getFormById(id: string) {
    return this.firestore.collection(rfmCollection).doc(id).get();

    // ,ref => ref.where('id', '==', id)
    //      .limit(1))
    //      .snapshotChanges()

  }

  getFormWithQueryString(qry: string) {
    let val: string, operator: string, property: string;

    return this.firestore.collection(rfmCollection, ref =>
      ref.where('name', '==', qry).limit(1)).snapshotChanges();
  }
  
  private convertFormToFirebase(form: Rfm) {
    let tmpObj = {};
    //loop throug managers set from location service. Only managers for the location will be sent to the database
    for (let prop in form.managers) {
      if (Object.prototype.hasOwnProperty.call(form.managers, prop)) {
        // for each manager fill out the object for it. We may use an interface here instead for doing this manually later.
        tmpObj[prop] = {
          groupName: prop,
          comment: '',
          required: true,
          signature: '',
          timeStamp: '',
          approved: false
        }

      }


    }
    //convert the request to the format that firebase wants
    let obj = {
      formState: form.formState,
      request: {
        requestorName: form.request.requestorName,
        timeStamp: form.request.timeStamp,
        rfmNumber: form.request.rfmNumber,
        wrkOrder: form.request.wrkOrder,
        processArea: form.request.processArea,
        duration: form.request.duration,
        safety: form.request.safety,
        request: form.request.request,
        removeTimeStamp: form.request.removeTimeStamp,

      },
      engineer: {
        engineerName: form.engineer.engineerName,
        changeTest: form.engineer.changeTest,
        drawingChanges: form.engineer.drawingChanges,
        timeStamp: form.engineer.timeStamp
      }

    };
    obj['managers'] = tmpObj;
    return obj;
  }

  createForm(form: Rfm) {

    // console.log(obj);

    return this.firestore.collection('RFM-APP').add(this.convertFormToFirebase(form));//.toFireStore());
  }
  updateForm(form: Rfm) {
    // delete form.id;
    //update document with RFM number. On create the updaeform data will contain the newly created autogenerated firebase id
    
    let docRef = this.firestore.collection(rfmCollection).doc(form.request.rfmNumber);
    return docRef.update(form);
    // this.firestore.doc(rfmCollection+'/' + form.request.rfmNumber).update(form);
  }
  setId(doc: Rfm) {

    let obj = this.convertFormToFirebase(doc);
    
    let docRef = this.firestore.collection(rfmCollection).doc(doc.request.rfmNumber);
    docRef.set(obj).then(rslt => {
      // console.log(rslt);
    })
  }

  deleteForm(form: Rfm) {
    // this.firestore.doc(rfmCollection+'/' + form.id).delete();
  }
}
