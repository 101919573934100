import { Component, OnInit, Input } from '@angular/core';
import { Rfm, Manager, Engineer, Request } from '../models/rfm'
import { EmailNotification } from '../models/notification'
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../services/form.service'
import { iAppUser, AppUser } from '../models/app-user';
import { LocationService } from '../services/location.service';
import { NotificationService } from '../services/notification.service';
import { ILocation, Location } from '../models/location'
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { observable } from 'rxjs';
import * as _ from 'lodash';
import { not } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  activeDoc: Rfm;

  activeId: string;
  formData: Rfm;
  forms: any[];
  locations: Location[];
  authLocations: any;
  removalDate: string;
  removalTime: string;
  firebaseUID = '';
  firebaseName = '';
  activeDocCopy: Rfm;
  signatureGroups = [];
  signature = {};
  managers = Array<Manager>();
  authorizedEngineer = false;
  authorizedManager = false;
  implemented = false;
  reviewed = false;
  fileAttachments;
  rfmDisable = true;
  managerDisable = true;
  engineerDisable = true;
  activeApproval = false;
  userName = '';

  engineers = Array<Engineer>();



  //users: AppUser[]; doesnt appear to be used in rfm component
  // @Input('user') user: AppUser;
  user: AppUser;
  // injected in rfm component private formservice: FormService, private locationService: LocationService, private userService: UserService, public auth: AuthService
  // injected in approval constructor(private locationService: LocationService, public auth: AuthService, private formservice: FormService) {
  constructor(private route: ActivatedRoute, private formservice: FormService,
    private locationService: LocationService, private userService: UserService,
    public notification: NotificationService, public auth: AuthService,
     private router: Router) {

    this.activeDoc = new Rfm();
    this.user = new AppUser();
    this.authLocations = new Array();
    this.auth.user$.subscribe(fbuser => {
      this.user.name = fbuser.displayName;
      this.user.email = fbuser.email;
      this.firebaseUID = fbuser.uid;
      this.firebaseName = fbuser.displayName;
      this.activeDocCopy = new Rfm();
      this.rfmDisable = true;
      this.managerDisable = true;
      this.engineerDisable = true;
      this.activeApproval = false;

    });




  }
  async processLocation() {
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as ILocation
        };//as any;
      })
      //after location service returns locations, run through locations array

      for (let location of this.locations) {
        //go through the properties of the current location
        for (let prop in location) {// if the object has the property
          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName'] == 'Requester' || this.activeDoc.formState == 'submitted') // and the group is the requestor group
                for (let u of location[prop]['users']) // go throught the users array looking for the current logged in user
                  if (u['uid'] == this.firebaseUID)
                    this.authLocations.push(location);
              if (location[prop]['groupName'] == 'Engineer') // and the group is the requestor group
                for (let u of location[prop]['users']) // go throught the users array looking for the current logged in user
                  if (u['uid'] == this.firebaseUID)
                    this.authorizedEngineer = true;//Locations.push(location);
              if (location[prop]['groupName'].endsWith('Manager')) // and the group is the requestor group
                for (let u of location[prop]['users']) // go throught the users array looking for the current logged in user
                  if (u['uid'] == this.firebaseUID)
                    this.authorizedManager = true;//Locations.push(location);
            }

        }
      }


      // console.log(this.authLocations);
      let currentLocation: Location;
      let tempArray = [];
      this.managers = this.activeDoc.managers;
      if (this.locations !== undefined && this.activeDoc !== undefined) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.activeDoc.request.processArea == this.locations[i]['name']) {
            currentLocation = this.locations[i];
          }
          else {
            continue;
          }
        }

        for (let group in currentLocation) {

          if (currentLocation[group]['groupName'] && currentLocation[group]['groupName'].endsWith("Manager")) {
            tempArray.push(
              currentLocation[group]
            );
            let tmpString = currentLocation[group]['groupName'];
            this.signature[tmpString] = '';
          }
        }
      }
      this.signatureGroups = [].concat.apply([], tempArray);
      this.rfmDisable = (this.activeDoc.formState != '' && !this.reviewed) && this.activeDoc.formState !== 'implemented';
      this.managerDisable = this.activeDoc.formState == 'approved' || this.activeDoc.formState == 'implemented' ||
        this.activeDoc.formState == 'complete' || !this.authorizedManager;
      this.engineerDisable = !this.authorizedEngineer || this.activeDoc.formState == 'implemented' ||
        this.activeDoc.formState != 'approved';

    });
  }
  async refreshForm() {
    this.route.paramMap.subscribe(param => {
      this.activeId = '';
      this.activeId = param.get("id")
      if (this.activeId == null) {
        this.activeDoc = new Rfm();
        this.processLocation(); // process locations fills data for activedoc so call before copy to docCopy
        this.activeDocCopy = new Rfm();
        
      }
      else {

        this.formservice.getFormById(this.activeId).subscribe(data => {
          // let temp = data.data();
          this.activeDoc = new Rfm();
          this.activeDoc = _.cloneDeep(data.data() as Rfm);
          this.processLocation(); // process locations fills data for activedoc so call before copy to docCopy
          this.activeDocCopy = _.cloneDeep(this.activeDoc); // for approval code
          
          if (this.activeDoc.formState == 'implemented')
            this.implemented = true;

          // this.activeDoc = temp;  
        })
        //this.getFormById(this.activeId);

      }
    });



    /**************************************************WORKING *******************************/

  }
  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      this.userName = params['user'];
      this.refreshForm();

      
    })
    

  }

  ngOnChanges(): void {

    this.refreshForm();

  }

  validateRequest() {
    if (this.activeDoc.request.processArea && this.activeDoc.request.request &&
      ((this.removalDate && this.removalTime && this.activeDoc.request.duration == "temporary") ||
        this.activeDoc.request.duration == 'permanent'))
      return true;
    else return false;
  }
  onPostRfm() {


    //NEW CODE 20200810 DLB


    //if we are not doing a review here, we are creating a new one
    if (this.activeDoc.formState !== 'implemented' && this.activeDoc.formState != 'complete' && this.validateRequest()) {
      /// SET FORMSTATE TO DESIRED STATE
      /****************************** NEED TO ADD CODE BELOW IN THE .THEN CLAUSE TO MAKE SURE WAS INSERTED, ELSE CHANGE NOT SUBMITTED */

      this.activeDoc.formState = 'submitted';
      this.activeDoc.request.timeStamp = new Date().toString();
      if (this.activeDoc.request.duration === 'temporary') {
        this.activeDoc.request.removeTimeStamp = new Date(this.removalDate + " " + this.removalTime).toISOString()
      }
      this.activeDoc.request.requestorName = this.user.name;
      for (let loc of this.authLocations) {
        for (let prop in loc) {// if the object has the property

          if (Object.prototype.hasOwnProperty.call(loc, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (loc[prop]['groupName'].endsWith('Manager')) {
                let prtyname = loc[prop]['groupName'];
                let obj = new Manager();
                obj.approved = false;
                obj.comment = '';
                obj.signature = '';
                obj.timeStamp = '';
                //this.activeDoc.managers.push(obj);
                this.activeDoc.managers[loc[prop]['groupName']] = obj;


              }
              // console.log(location[prop]['groupName'])

            }

        }
      }
      // since engineer is not yet put in rfm because we roll through the groups to create the rest of the rfm
      let eng = new Engineer;
      this.activeDoc.engineer = eng;
      this.activeDoc.request.email = this.user.email;
      // this.activeDoc.engineer.email = this.user.email;





      this.formservice.createForm(this.activeDoc).then(e => {

        // COPYING SUBMITTED TO ACTIVE DOC ONLY AFTER THE RECORD WAS SUCESSFULLY PUT IN THE DATBASE DURING THIS CALL BACK
        // COPYING TO DOC MAY NOT BE NECESSARY
        if (this.activeDoc.request.rfmNumber != e.id) {
          this.activeDoc.request.rfmNumber = e.id;

          this.formservice.setId(this.activeDoc)

        }
        // need to set an array of notifications based on the current docs managers list,
        // must go through list of locations and get managers for this location and que up notifications for all available
        for (let loc of this.locations) {
          if (loc.name === this.activeDoc.request.processArea) {
            for (let prop in loc) {
              if (Object.prototype.hasOwnProperty.call(loc, prop)) {
                if (prop.startsWith('group')) {  // and property is one of the groups
                  if (loc[prop]['groupName'].endsWith('Manager')) {
                    for (let u of loc[prop]['users']) {
                      let notice = new EmailNotification();
                      notice.rfmNumber = e.id;
                      notice.userName = u.name;
                      notice.userEmail = u.email; //need to add email to all  in locations
                      notice.rfmDetails = `<h5>User ${this.activeDoc.request.requestorName} has submitted an RFM </h5> 
                       <p> Location: ${this.activeDoc.request.processArea}</p>
                        <p> RFM Brief - ${this.activeDoc.request.request.substr(0, 50)}</p> `
                      this.notification.createNotification(notice).then(rslt => {

                      }).catch(err => {
                        console.log(err)
                      });

                    }
                  }
                  // console.log(location[prop]['groupName'])
                }
              }
            }
          }
        }
        // let notice = new EmailNotification();
        // notice.rfmNumber = e.id;
        // notice.userName = this.user.name;
        // notice.userEmail = this.user.email;
        // notice.rfmDetails = `<h1>User ${this.activeDoc.request.requestorName} has submitted an RFM </h1> 
        // <p> RFM Number ${e.id}</p> `

        // this.notification.createNotification(notice).then(rslt => {

        // }).catch(err => {
        //   console.log(err)
        // });

      })

    }//end if not trying to create a new form, we are reviewing one.
    else {
      if (this.activeDoc.formState == 'implemented' && this.reviewed) {
        this.activeDoc.formState = 'complete';
        this.activeDoc.request.timeStamp = new Date().toString();
        this.formservice.updateForm(this.activeDoc).then(e => {

          this.processLocation();
        })

      }
    }

  }

  onPostApproval() {

    let tempRole;
    let tempSig;
    let allSignatures = false;
    //set timestamp of post / change for user who sumbitted
    for (let prop in this.activeDocCopy.managers) {
      if (this.activeDocCopy.managers[prop]['signature'] == this.firebaseName) {
        this.activeDocCopy.managers[prop]['email'] = this.user.email;
        this.activeDocCopy.managers[prop]['timeStamp'] = new Date().toISOString();
      }

    }

    allSignatures = true;
    for (let manager in this.activeDocCopy['managers']) {
      if (this.activeDocCopy['managers'][manager]['signature'] == this.firebaseName) {
        this.activeApproval = this.activeDocCopy['managers'][manager]['approved']
      }
      if (!this.activeDocCopy['managers'][manager]['signature'] || !this.activeDocCopy['managers'][manager]['approved'])
        allSignatures = false;
    }
    this.activeDoc = _.cloneDeep(this.activeDocCopy);
    if (allSignatures) {
      this.activeDoc.formState = 'approved';
    }

    this.formservice.updateForm(this.activeDoc).then(e => {
      if (this.activeDoc.formState == 'approved') {
        for (let loc of this.locations) {
          if (loc.name === this.activeDoc.request.processArea) {
            for (let prop in loc) {
              if (Object.prototype.hasOwnProperty.call(loc, prop)) {
                if (prop.startsWith('group')) {  // and property is one of the groups
                  if (loc[prop]['groupName'].endsWith('Engineer')) {
                    for (let u of loc[prop]['users']) {
                      let notice = new EmailNotification();
                      notice.rfmNumber = this.activeDoc.request.rfmNumber;
                      notice.userName = u.name;
                      notice.userEmail = u.email; //need to add email to all  in locations
                      notice.rfmDetails = `<h5>RFM  has been approved </h5> 
                       <p> Location: ${this.activeDoc.request.processArea}</p>
                        <p> RFM Brief - ${this.activeDoc.request.request.substr(0, 50)}</p> `
                      this.notification.createNotification(notice).then(rslt => {

                      }).catch(err => {
                        console.log(err)
                      });

                    }
                  }
                  // console.log(location[prop]['groupName'])
                }
              }
            }
          }
        }

      }
      else {

        for (let loc of this.locations) {
          if (loc.name === this.activeDoc.request.processArea) {
            for (let prop in loc) {
              if (Object.prototype.hasOwnProperty.call(loc, prop)) {
                if (prop.startsWith('group')) {  // and property is one of the groups
                  if (loc[prop]['groupName'].endsWith('Requester')) {
                    for (let u of loc[prop]['users']) {
                      if (u.name == this.activeDoc.request.requestorName) {
                        let notice = new EmailNotification();
                        notice.rfmNumber = this.activeDoc.request.rfmNumber;
                        notice.userName = u.name;
                        notice.userEmail = u.email; //need to add email to all  in locations
                        let tempString = this.activeApproval ? 'approved' : 'disapproved'
                        notice.rfmDetails = `<h5>${this.user.name} has ${tempString} your RFM </h5> 
                            <p> Location: ${this.activeDoc.request.processArea}</p>
                              <p> RFM Brief - ${this.activeDoc.request.request.substr(0, 50)}</p> `
                        this.notification.createNotification(notice).then(rslt => {

                        }).catch(err => {
                          console.log(err)
                        });
                      }

                    }
                  }
                  // console.log(location[prop]['groupName'])
                }
              }
            }
          }
        }
      }

    })
  }

  onPostAction() {
    this.activeDocCopy.engineer.engineerName = this.firebaseName;
    this.activeDocCopy.engineer.timeStamp = new Date().toISOString();
    this.activeDocCopy.engineer.email = this.user.email;
    this.activeDoc = _.cloneDeep(this.activeDocCopy);
    if (this.implemented) {
      this.activeDoc.formState = 'implemented';
    }

    this.formservice.updateForm(this.activeDoc).then(e => {

      for (let loc of this.locations) {
        if (loc.name === this.activeDoc.request.processArea) {
          for (let prop in loc) {
            if (Object.prototype.hasOwnProperty.call(loc, prop)) {
              if (prop.startsWith('group')) {  // and property is one of the groups
                if (loc[prop]['groupName'].endsWith('Requester')) {
                  for (let u of loc[prop]['users']) {
                    if (u.name == this.activeDoc.request.requestorName) {
                      let notice = new EmailNotification();
                      notice.rfmNumber = this.activeDoc.request.rfmNumber;
                      notice.userName = u.name;
                      notice.userEmail = u.email; //need to add email to all  in locations
                      notice.rfmDetails = `<h5>RFM  has been implemented </h5> 
                            <p> Location: ${this.activeDoc.request.processArea}</p>
                              <p> RFM Brief - ${this.activeDoc.request.request.substr(0, 50)}</p> `
                      this.notification.createNotification(notice).then(rslt => {

                      }).catch(err => {
                        console.log(err)
                      });
                    }

                  }
                }
                // console.log(location[prop]['groupName'])
              }
            }
          }
        }
      }

    })

  }

}
