// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDrf8maR8P5KTbChVh4gZBdmfSHgsI-Iy4",
    authDomain: "industrialworks-87538.firebaseapp.com",
    databaseURL: "https://industrialworks-87538.firebaseio.com",
    projectId: "industrialworks-87538",
    storageBucket: "industrialworks-87538.appspot.com",
    messagingSenderId: "124431414982",
    appId: "1:124431414982:web:7a74253c5e42f21229c9c7",
    measurementId: "G-L9DG18BL4F"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
