import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {AppUser} from '../models/app-user';
import * as firebase from 'firebase'; 
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor( private afAuth: AngularFireAuth,private firestore: AngularFirestore) {}

  createUser(user: AppUser, pass: string){
    // console.log(user)
    this.afAuth.createUserWithEmailAndPassword(user.email,pass ).then(fbuser=>{
      fbuser.user.updateProfile({displayName:user.name}).then(e=>{
        let docRef = this.firestore.collection('users')
        .doc(fbuser.user.uid)
        docRef.update(user)
       // console.log(fbuser.user.displayName);

      })
    })

  }

}
