import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import {LoginComponent} from './login/login.component';
import {FormlistComponent} from './formlist/formlist.component'
import {FormComponent} from './form/form.component';
import {AdminComponent} from './admin/admin.component';
import { LoginComponent } from './login/login.component';
//import { AppComponent } from './app.component';

const routes: Routes = [
  {path:'', component: LoginComponent},
  {path:'formlist', component: FormlistComponent},
  {path:'form/:id', component: FormComponent},
  {path:'form', component: FormComponent},
  {path:'admin', component: AdminComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[],
})
export class AppRoutingModule {
  
 }
