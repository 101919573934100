import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Rfm,Engineer } from '../models/rfm';
import { LocationService } from '../services/location.service';
import { AuthService } from '../services/auth.service';
import { FormService } from '../services/form.service'
import { Location, Approval, ILocation } from '../models/location'
import * as _ from 'lodash';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent {
 changeDetails;
 drawingsModified;
 fileAttachments;
 firebaseUID = '';
 firebaseName = '';
 locations: Location[];
 activeDocCopy : Rfm;
 authLocations: any;
 authorizedEngineer = false;
 implemented = false;


 engineers = Array<Engineer>();
 @Input('activeDoc') activeDoc: Rfm;
 //@Input('locations') locations: Location;
 //@Output('action') action = new EventEmitter();
 constructor(private locationService: LocationService, public auth: AuthService, private formservice: FormService){
  //this.activeDoc = new Rfm();
  this.auth.user$.subscribe(user => {
    this.firebaseUID = user.uid;
    this.firebaseName = user.displayName;
  });
  this.activeDocCopy = new Rfm();
  this.authLocations = new Array();
 }

 sendAction(){
  //  this.action.emit(
  //    {
  //      changeDetails:this.changeDetails,
  //      drawingsModified:this.drawingsModified,
  //      fileAttachments:this.fileAttachments,
  //    }
  //  );
 }
 submit(){
   console.log('submitted');
 }
 ngOnChanges(){

  this.locationService.getLocations().subscribe(data => {
    this.locations = data.map(e => {
      return {
        id: e.payload.doc.id,
        ...e.payload.doc.data() as ILocation
      };//as any;
    })
    //after location service returns locations, run through locations array

      for (let location of this.locations) {
        //go through the properties of the current location
        for (let prop in location) {// if the object has the property
          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName'] == 'Engineer') // and the group is the requestor group
                for (let u of location[prop]['users']) // go throught the users array looking for the current logged in user
                  if (u['uid'] == this.firebaseUID)
                    this.authorizedEngineer = true;//Locations.push(location);
              // soore location in authroized locations for the user.

              //CHANGES FOR GIT
              // console.log(location[prop]['groupName'])
            }

        }
      }   
   // console.log(this.authLocations);

  });
  this.activeDocCopy = _.cloneDeep(this.activeDoc);
 }

}
