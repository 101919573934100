import { Component, OnInit } from '@angular/core';
import { LocationService } from '../services/location.service';
import { Location } from '../models/location'

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  locations: Location[];
  groups: [{}];
  areas: [{}];
  selLocation = Array<String>();
  activeLocation: string;
  constructor(private locationService: LocationService) {
    this.activeLocation = 'test';
    this.groups = [{}];
    this.areas = [{}];
   



  }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Location
        };//as any;
      })
      this.areas.pop();// pop the first blank index off
      for (let location of this.locations) {
        //this.areas["Location"] = location;
        this.groups=[{}];
        this.groups.pop(); // pop the blank record
        for (let prop in location) {// if the object has the property
          
          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              //  if( location[prop]['groupName'] == 'Requester') // and the group is the requestor group
              //      for( let u of location[prop]['users'] ) // go throught the users array looking for the current logged in user
              //          if(u['uid'] == this.fbUserId)
              //              this.authLocations.push(location) // soore location in authroized locations for the user.

              if (location[prop]['groupName']) {
                let prtyname = location[prop]['groupName'];
                let obj ={};
                obj['Role']=prtyname;
                obj[prtyname]=false
                this.groups.push(obj)
              }
              // console.log(location[prop]['groupName'])

            }

        }
       // this.areas["Location"][location.name] = this.groups;
        this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
        this.areas.push({groupName:location.name,groups:this.groups});
        //this.areas[location.name]=this.groups;
      }
      

    }); // end locationserve get locations


  }//end ngoninit
  onLocChange() {
    const locName = this.activeLocation;
    //console.log(this.activeLocation);
    let newArray = this.locations.filter(function (item) {
      return item.name == locName;
    });

    let tmpLoc = newArray[0];
    let groupLabels = new Array(String)
    //this.selLocation = newArray[0];
    this.selLocation.length = 0;
    for (let prop in tmpLoc) {// if the object has the property
      if (Object.prototype.hasOwnProperty.call(tmpLoc, prop))
        if (prop.startsWith('group')) {  // and property is one of the groups
          //  if( location[prop]['groupName'] == 'Requester') // and the group is the requestor group
          //      for( let u of location[prop]['users'] ) // go throught the users array looking for the current logged in user
          //          if(u['uid'] == this.fbUserId)
          //              this.authLocations.push(location) // soore location in authroized locations for the user.

          if (tmpLoc[prop]['groupName'])
            this.selLocation.push(tmpLoc[prop]['groupName'])
          // console.log(location[prop]['groupName'])
        }

    }
    // console.log(typeof(this.selLocation[0]));


  }
}
