
export interface iAppUser { 
  name: string;
  email: string; 
  isAdmin: boolean;
  isManager: boolean;
  isEngineer: boolean;
  isRequestor: boolean;
}
export class AppUser implements iAppUser{
  name: string;
  email: string; 
  isAdmin: boolean;
  isManager: boolean;
  isEngineer: boolean;
  isRequestor: boolean;
  constructor(){
    this.name = '';
    this.email='';
    this.isAdmin=false;
    this.isManager=false;
    this.isEngineer=false;
    this.isRequestor=false;
  }
}