export class Request {
    requestorName: string = '';
    timeStamp: string = '';
    rfmNumber: string = '';
    wrkOrder: string = '';
    processArea: string = '';
    duration: string = '';
    safety: string = '';
    request: string = '';
    removeTimeStamp: string = '';
    email: string;

};
export class Manager {
    required: boolean = false;
    signature: string = '';
    timeStamp: string = '';
    comment: string = '';
    approved: boolean = false;
    email: string;

}
export class Engineer {
    engineerName: string = '';
    changeTest: string = '';
    drawingChanges: string = '';
    timeStamp: string = '';
    email: string;

}
export class Rfm {
    formState: string; // = '';
    request: Request;
    managers: Manager[];
    
     engineer: Engineer;

    constructor() {
        this.formState = '';
        this.request = new Request();
        this.managers = new Array<Manager>();

         this.engineer = new Engineer();


    }






}
