import {
  Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LocationService } from '../services/location.service';
import { Location } from '../models/location'
import { AppUser } from '../models/app-user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Extractor } from '@angular/compiler';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { Subscription } from 'rxjs';


import firestore from 'firebase/app';
import { Observable } from 'rxjs';
//import * as firebase from 'firebase/app';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnChanges, OnDestroy {
  locations$: Observable<Location[]>;
  @Output() resetAdminSelects = new EventEmitter<void>();
  userName: string;
  email: string;
  password: string;
  userId: string;
  isAdmin: boolean;
  locations: Location[] = [];
  private locationSubscription: Subscription;
  users: AppUser[];
  groups: [{}];
  areas: [{}];
  nUser: AppUser;
  isDirty: boolean = false;
  updating: boolean = false;
  errorUpdating: boolean = false;
  selectedId: String;
  userLocationRoles: [{}];
  userAdded: boolean = false;


  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private firestore: AngularFirestore,
    private locationService: LocationService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.userName = '';
    this.email = '';
    this.password = '';
    this.userId = '';
    this.isAdmin = false;
    this.groups = [{}];
    this.areas = [{}];
    this.nUser = new AppUser();
  }
  setGroups() {
    this.groups = [{}];
    this.areas = [{}];
    this.userLocationRoles = [{}];
    this.areas.pop();// pop the first blank index off
    for (let location of this.locations) {
      //this.areas["Location"] = location;
      this.groups = [{}];
      this.groups.pop(); // pop the blank record
      for (let prop in location) {// if the object has the property

        if (Object.prototype.hasOwnProperty.call(location, prop))
          if (prop.startsWith('group')) {  // and property is one of the groups
            if (location[prop]['groupName']) {
              let prtyname = location[prop]['groupName'];
              let obj = {};
              obj['Role'] = prtyname;
              obj[prtyname] = false
              this.groups.push(obj)
            }
            // console.log(location[prop]['groupName'])
          }
      }
      // this.areas["Location"][location.name] = this.groups;
      this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
      this.areas.push({ groupName: location.name, groups: this.groups });
      //this.areas[location.name]=this.groups;
    }

  }
  updateGroupUsage() {
    try {
      //this.userName = this.userLocationRoles['name'];
      //this.email = this.userLocationRoles['email'];

      for (let location of this.locations) {
        //this.areas["Location"] = location;
        this.groups = [{}];
        this.groups.pop(); // pop the blank record
        for (let prop in location) {// if the object has the property

          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName']) {
                let prtyname = location[prop]['groupName'];
                let obj = {};
                obj['Role'] = prtyname;
                if (this.userLocationRoles[location.name]) {
                  if (this.userLocationRoles[location.name].roles.indexOf(prtyname) != -1) {
                    obj[prtyname] = true;
                  }
                  else {
                    obj[prtyname] = false;
                  }

                }
                else {
                  obj[prtyname] = false
                }

                this.groups.push(obj)
              }
              // console.log(location[prop]['groupName'])
            }
        }
        // this.areas["Location"][location.name] = this.groups;
        this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
       
      }
    }
    catch (err) { };
  }
  ngOnInit(): void {
    
    this.locationSubscription = this.locationService.getLocations().subscribe(
      data => {
        this.locations = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data() as Location
          };//as any;
        })
        //this.setGroups();
        this.updateGroupUsage();
      }
    )
   

    //GET USERS
    this.userService.getUsers().subscribe(data => {
      this.users = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as AppUser
        };//as any;
      })
    })

  }
  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    //this.locations=null;


    this.groups = [{}];
    this.areas = [{}];
    this.groups.pop();
    this.areas.pop();
    try {
      this.userName = this.userLocationRoles['name'];
      this.email = this.userLocationRoles['email'];
      this.userId = this.userLocationRoles['id'];

      for (let location of this.locations) {
        //this.areas["Location"] = location;
        this.groups = [{}];
        this.groups.pop(); // pop the blank record
        for (let prop in location) {// if the object has the property

          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName']) {
                let prtyname = location[prop]['groupName'];
                let obj = {};
                obj['Role'] = prtyname;
                if (this.userLocationRoles[location.name]) {
                  if (this.userLocationRoles[location.name].roles.indexOf(prtyname) != -1) {
                    obj[prtyname] = true;
                  }
                  else {
                    obj[prtyname] = false;
                  }

                }
                else {
                  obj[prtyname] = false
                }

                this.groups.push(obj)
              }
              // console.log(location[prop]['groupName'])
            }
        }
        // this.areas["Location"][location.name] = this.groups;
        this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
        //let obj = {};
        //this.groups.push(obj);
        //this.areas.push({ groupName: location.name, groups: this.groups });
        //this.areas[location.name]=this.groups;

      }
    }
    catch (err) { };


    //console.log(this.userLocationRoles)
  }

  async setSelectedId(id) {
    let tmpUser = this.users.find(ele => ele['id'] === id);
    this.setGroups();
    this.userName = tmpUser.name;
    this.email = tmpUser.email;
    this.userId = tmpUser['id'];

    this.userLocationRoles = [{}];

    this.selectedId = id;



    for (let location of this.locations) {

      //this.userLocations.pop();
      for (let prop in location) {// if the object has the property
        if (Object.prototype.hasOwnProperty.call(location, prop))
          if (prop.startsWith('group')) {  // and property is one of the groups
            if (location[prop]['groupName'] !== '') {

              let users = location[prop]['users'];
              // let tmpArray = [{ 'location': location.name, 'roles': [{ 'role': location[prop]['groupName'] }] }]
              let userFrmGrp = users.find((data) => data.uid === id);
              if (userFrmGrp) {
                let idx = users.findIndex((element) => element.uid === id);
                this.userLocationRoles['userId'] = id;
                let areaIdx = this.areas.findIndex(ele => ele['groupName'] === location['name'])
                if (areaIdx != undefined) {
                  let roleIdx = this.areas[areaIdx]['groups'].findIndex(ele => ele['Role'] === location[prop]['groupName']);
                  this.areas[areaIdx]['groups'][roleIdx][location[prop]['groupName']] = true;
                }

                this.userLocationRoles['name'] = users[idx]['name'];
                this.userLocationRoles['email'] = users[idx]['email'];
                if (this.userLocationRoles) {//if locationroles exits 
                  //if propery is a location name push to user locations
                  if (Object.prototype.hasOwnProperty.call(this.userLocationRoles, location.name)) {
                    this.userLocationRoles[location.name].roles.push(location[prop]['groupName'])
                  }
                  else {//otherwise create the location name and group name
                    this.userLocationRoles[location.name] = { 'name': location.name, 'roles': [] };
                    this.userLocationRoles[location.name].roles.push(location[prop]['groupName']);
                  }
                }

              }
             


            }
            // console.log(location[prop]['groupName'])
          }
        //console.log(this.userLocationRoles)
      }

    }





  }
  

  

  updateUser() {

    this.nUser.name = this.userName;
    this.nUser.email = this.email;
    this.nUser.isAdmin = this.isAdmin;


    this.updating = true;
    
    if (this.userId) {
      this.firestore.collection('users').doc(this.userId).set(
        {
          name: this.userName, email: this.email, isAdmin: this.isAdmin,
          isRequestor: false, isEngineer: false, isManager: false
        })
        .then(e => {
          console.log("user edit successful");
        })
        .catch(err => {
          this.updating = false;
          this.errorUpdating = true;
        })
        .finally(() => {
          //console.log('finally')
        }
        )
      //go through each location and group
      for (let loc of this.locations) {

        //for each area found, change the locations property for this user
        //found area map from html for location from database, now updat locations for each role

        for (let locObj in loc as any) {
          //we interate over the location objects properties
          //if we find a group object we will attempt to find the group in the areas map to match
          if (locObj.startsWith('group')) {
            //find the group in the matching location object of the area map
            let areaFound = this.areas.find((ele) => {
              return ele['groupName'] === loc.name;
            })
            //if we found a matching area map for the location
            //go over each of the loc object groups and see if the user is in it
            // if in, see if the area map has the user assigned true for the role
            //and the user is in the loc group, then nothing to do
            //if true and user is not in the loc group, then add to the location group
            //if the user is not assigned true, see if the users email is in the loc group
            // if not, then nothing to do, if it is then remove the user
            //if the 
            if (areaFound) {
              let tmpLocGrpUser = loc[locObj]['users'].find((ele) => {
                return ele['uid'] === this.userId;
              }
              )
              //user is found as loc group role
              if (tmpLocGrpUser) {
                // find if role is defined as true
                let role = areaFound['groups'].find((ele) => {
                  return ele['Role'] === loc[locObj]['groupName'];
                })//user is true for role
                if (role && role[role['Role']]) {
                  this.updating = false;
                  this.isDirty = false;
                  this.errorUpdating = true;

                }
                else {
                  this.updating = false;
                  this.isDirty = false;
                  this.errorUpdating = true;
                  let tmpId = loc['id'];
                  let grpToUpdate = locObj + '.users';
                  const docRef = this.firestore.collection('location').doc(tmpId);
                  const user = {
                    name: this.userName,
                    email: this.email,
                    uid: this.userId,
                  };
                  docRef.update({ [grpToUpdate]: firestore.firestore.FieldValue.arrayRemove(user) })
                    .then(() => { this.isDirty = false; this.updating = false; })
                    .catch((err) => {
                      this.updating = false;
                      this.isDirty = false;
                      this.errorUpdating = true;
                    })
                    .finally(() => {
                      console.log('user ' + user.name + ' removed');
                    })
                  console.log('user will be removed from group ' + role['Role'])
                  //user was found but his area map is not set remove the user
                }
              }//end of user was found for the role in the database
              else {// user was not found , now check if he has the role set, if so add him
                let role = areaFound['groups'].find((ele) => {
                  return ele['Role'] == loc[locObj]['groupName'];
                })//user is true for role
                if (role && role[role['Role']]) {
                  this.updating = false;
                  this.isDirty = false;
                  this.errorUpdating = true;
                  let tmpId = loc['id'];
                  let grpToUpdate = locObj + '.users';
                  const docRef = this.firestore.collection('location').doc(tmpId);
                  const user = {
                    name: this.userName,
                    email: this.email,
                    uid: this.userId,
                  };
                  docRef.update({ [grpToUpdate]: firestore.firestore.FieldValue.arrayUnion(user) })
                    .then(() => { this.isDirty = false; this.updating = false; })//this.router.navigate(['/formlist'])
                    .catch((err) => {
                      this.updating = false;
                      this.isDirty = false;
                      this.errorUpdating = true;
                    })

                  console.log('user will be added to group ' + role['Role'])
                }
                else {
                  this.updating = false;
                  this.isDirty = false;
                  this.errorUpdating = true;
                  //nothing to do the user is not a member
                }
              }

            }

          }
        }

      }


    }
  }//end of if user id








}
