import { UserService } from './user.service';
import { AppUser } from '../models/app-user';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';


import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of'; 
import * as firebase from 'firebase'; 
import {switchMap} from 'rxjs/operators'
import { UserCredential } from '@firebase/auth-types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<firebase.User>;

  constructor(
    private userService: UserService,
    private afAuth: AngularFireAuth, 
    private route: ActivatedRoute) { 
    this.user$ = afAuth.authState;    
  }

  login() {
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    localStorage.setItem('returnUrl', returnUrl);
    
    this.afAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
  }
  loginEmailPass(email, pass): Promise<UserCredential>{
    return this.afAuth.signInWithEmailAndPassword(email,pass)
    
  }
  logout() { 
    this.afAuth.signOut();
  }
  
  

  // get appUser$() : Observable<AppUser> {
  //   // return this.user$
  //   //   .switchMap(user => {
  //   //     if (user) return this.userService.get(user.uid);

  //   //     return Observable.of(null);
  //   //   });    

  //     // return this.user$
  //     // .pipe( 
  //     //   switchMap(user => {
  //     //           if (user) return this.userService.get(user.uid).valueChanges();

  //     //            return Observable.of(null);
  //     //         })   
  //     // )

     
  // }
}
