import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormService } from '../services/form.service'
import { Rfm, Manager, Engineer, Request } from '../models/rfm'
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { iAppUser, AppUser } from '../models/app-user';


@Component({
  selector: 'app-formlist',
  templateUrl: './formlist.component.html',
  styleUrls: ['./formlist.component.css']
})
export class FormlistComponent implements OnInit {
  //forms: Rfm[];
  forms: any[];
  activeDoc: Rfm;
  selectedId: string;
  dateAssend = true;
  areaAssend = true;
  statusAssend = true;
  nameAssend = true;
  userName = '';
  private SORT_KEY: 'user_formList_order' ;
  private SORT_METHOD: 'user_formList_method';
  sortOrder: string | null = null;//sort order asc, desc
  sortMethod: string | null = null;//sort method request date, status, name, area
  constructor(private formservice: FormService, private route: ActivatedRoute, private router: Router,) {

  }


  ngOnInit(): void {
  this.route.queryParams.subscribe(params=>{
    this.userName = params['user'];
    
  })
  this.sortOrder = localStorage.getItem(this.SORT_KEY);
  this.sortMethod = localStorage.getItem(this.SORT_METHOD);
    this.formservice.getForms().subscribe(data => {
      this.forms = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Rfm
        };//as any;
      })
      const ascDesc = this.sortOrder;
      switch(this.sortMethod){
        case 'date':{
          this.dateAssend = ascDesc?true:false;
          this.sortByDate();
        }break;
        case 'name':{
          this.nameAssend = ascDesc?true:false;
          this.sortByName();
        }break;
        case 'area':{
          this.areaAssend = ascDesc?true:false;
          this.sortByArea();
        }break;
        case 'status':{
          this.statusAssend = ascDesc?true:false;
          this.sortByStatus();
        }break;
        default :{
          this.dateAssend = false;
          this.sortByDate();
        }
            
      }
     // this.sortByDate();
      
    });
  }
  create(form: Rfm) {
    this.formservice.createForm(form).then(docRef => {
      console.log(docRef.id);
    })

  }
  sortByStatus() {
    this.statusAssend = !this.statusAssend;
    this.sortMethod='status';
    this.sortOrder =  this.statusAssend? 'asc':'desc';
    localStorage.setItem(this.SORT_KEY, this.sortOrder);
    localStorage.setItem(this.SORT_METHOD,this.sortMethod);
    if (this.statusAssend) {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.formState.toLowerCase();
        let fb = b.formState.toLowerCase();
        if (fa > fb)
          return -1;
        if (fa < fb)
          return 1;
        return 0;
      })
    }
    else {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.formState.toLowerCase();
        let fb = b.formState.toLowerCase();
        if (fa < fb)
          return -1;
        if (fa > fb)
          return 1;
        return 0;
      })
    }
  }
  sortByName() {
    this.nameAssend = !this.nameAssend;
    this.sortMethod='name';
    this.sortOrder =  this.nameAssend? 'asc':'desc';
    localStorage.setItem(this.SORT_KEY, this.sortOrder);
    localStorage.setItem(this.SORT_METHOD,this.sortMethod);
    if (this.nameAssend) {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.request.requestorName.toLowerCase();
        let fb = b.request.requestorName.toLowerCase();
        if (fa < fb)
          return -1;
        if (fa > fb)
          return 1;
        return 0;
      })
    }
    else {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.request.requestorName.toLowerCase();
        let fb = b.request.requestorName.toLowerCase();
        if (fa > fb)
          return -1;
        if (fa < fb)
          return 1;
        return 0;
      })
    }
  }
  sortByArea() {
    this.areaAssend = !this.areaAssend;
    this.sortMethod='area';
    this.sortOrder =  this.areaAssend? 'asc':'desc';
    localStorage.setItem(this.SORT_KEY, this.sortOrder);
    localStorage.setItem(this.SORT_METHOD,this.sortMethod);
    if (this.areaAssend) {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.request.processArea.toLowerCase();
        let fb = b.request.processArea.toLowerCase();
        if (fa < fb)
          return -1;
        if (fa > fb)
          return 1;
        return 0;
      })
    }
    else {
      this.forms.sort((a: Rfm, b: Rfm) => {
        let fa = a.request.processArea.toLowerCase();
        let fb = b.request.processArea.toLowerCase();
        if (fa > fb)
          return -1;
        if (fa < fb)
          return 1;
        return 0;
      })
    }
  }
  sortByDate() {
    this.dateAssend = !this.dateAssend;
    this.sortMethod='date';
    this.sortOrder =  this.dateAssend? 'asc':'desc';
    localStorage.setItem(this.SORT_KEY, this.sortOrder);
    localStorage.setItem(this.SORT_METHOD,this.sortMethod);
    
    let dt = new Date();

    if (this.dateAssend == true) {

      this.forms.sort((a: Rfm, b: Rfm) => {
        var d1 = Date.parse(a.request.timeStamp);
        var d2 = Date.parse(b.request.timeStamp);
        if (d1 < d2)
          return -1;
        if (d1 > d2)
          return 1;
        return 0;
      })
    }
    else {
      this.forms.sort((a: Rfm, b: Rfm) => {
        var d1 = Date.parse(a.request.timeStamp);
        var d2 = Date.parse(b.request.timeStamp);
        if (d2 < d1)
          return -1;
        if (d2 > d1)
          return 1;
        return 0;
      })
    }

  }
  update(form: Rfm) {
    this.formservice.updateForm(form);
  }

  delete(id: string) {
    //this.formservice.deleteForm(id);
  }

  setSelectedId(id) {
    this.selectedId = id.target.innerHTML;
  }
  setSelectedForm(form: Rfm) {
    this.selectedId = form.request.rfmNumber;
  }
  //get the form using the document ID. Different syntax that get with filter
  getFormById(id) {
    let temp = id.target.innerHTML
    this.formservice.getFormById(temp).subscribe(data => {
      temp = data.data();
      this.activeDoc = new Rfm();
      this.activeDoc = temp;
      console.log(this.activeDoc);
      this.activeDoc = temp;

    })
  }
  getSelectedForm() {
    // var index = this.forms.map(function(o) { return o.attr1; }).indexOf("john");
    var index = this.forms.map((o) => o.request.rfmNumber).indexOf(this.selectedId);
    this.activeDoc = this.forms[index]
    // const navigationExtras: NavigationExtras = {
    //   state: {
    //   id: this.activeDoc.request.rfmNumber

    //   }

    // }
    this.router.navigate(['/form', {
      id: this.selectedId
    }]);
    //this.activeDoc = this.forms[index];

  }
  getFormWithQueryString(qString: string) {

    // this.snapshotResult.subscribe(doc => {
    //   this.myClient = <Client>doc.payload.doc.data();
    //   this.clientRef = doc.payload.doc.ref;

  }







}
