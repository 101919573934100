import { AppUser } from '../models/app-user';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase'; 
import { AngularFirestore } from '@angular/fire/firestore';
import { isElement } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private db: AngularFireDatabase,private firestore: AngularFirestore) { }
  

  save(user: firebase.User) {

    let docRef = this.firestore.collection('users').doc(user.uid)
    docRef.update({name:user.displayName,email: user.email})
     //this.firestore.doc('users'+'/' + form.request.rfmNumber).update(form);
    
    // this.db.object('/users/' + user.uid).update({
    //   //name: user.displayName,
    //   name: 'Dan L. Brown',
    //   email: user.email
    // });
  }
  getUserDetails(uid: string): Observable<AppUser | null> {
    return this.firestore
      .doc(`users/${uid}`)
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          const userDocument = snapshot.payload.data() as AppUser;
          return userDocument ? userDocument : null;
        })
      );
  }

  

  getUsers(){ 
    return this.firestore.collection('users').snapshotChanges();
  }
}
