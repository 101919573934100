import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';


import {RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
import { RequestModComponent } from './request-mod/request-mod.component';
import { ApprovalComponent } from './approval/approval.component';
import { ActionComponent } from './action/action.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FormlistComponent } from './formlist/formlist.component';
import { FormComponent } from './form/form.component';
import { AdminOptionsComponent } from './admin-options/admin-options.component';
import { AdminComponent } from './admin/admin.component';
import { LocationComponent } from './location/location.component';
import { AppRoutingModule } from './app-routing.module';
import { EditUserComponent } from './edit-user/edit-user.component';
import { NewUserComponent } from './new-user/new-user.component';


//import {AuthGuardService} from './services/auth-guard.service';
@NgModule({
  declarations: [
    AppComponent,
    RequestModComponent,
    ApprovalComponent,
    ActionComponent,
    LoginComponent,
    NavigationComponent,
    FormlistComponent,
    FormComponent,
    AdminOptionsComponent,
    AdminComponent,
    LocationComponent,
    EditUserComponent,
    NewUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
   // RouterModule.forRoot([
   //   
   //   {path:'formlist', component: FormlistComponent},
   //   {path:'form/:id', component: FormComponent},
   //   {path:'form', component: FormComponent},
   //   {path:'admin', component: AdminComponent},
   //   
   // ]),
  ],
  providers: [
    //AuthGuardService,
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
