  <div class="table-responsive">
    <!-- <table class="table table-striped table-sm"> -->
     
      <table class="table  table-sm">
      <thead>
        <tr>
          <button class='btn btn-primary' (click)='getSelectedForm()' >Get Form</button>
        </tr>
        <tr>
          <th (click)='sortByDate()' *ngIf="dateAssend ==true">Request Date&#9650;</th>
          <th (click)='sortByDate()' *ngIf="dateAssend ==false">Request Date&#9660;</th>
          <th >Rfm Number</th>
          <th (click)='sortByStatus()' *ngIf="statusAssend ==true" >Status&#9650;</th>
          <th (click)='sortByStatus()' *ngIf="statusAssend ==false" >Status&#9660;</th>
          <th (click)='sortByName()' *ngIf="nameAssend ==true">Name&#9650;</th>
          <th (click)='sortByName()' *ngIf="nameAssend ==false">Name&#9660;</th>
          <th (click)='sortByArea()' *ngIf="areaAssend ==true">Area&#9650;</th>
          <th (click)='sortByArea()' *ngIf="areaAssend ==false">Area&#9660;</th>
          <th>Request</th>
        </tr>
      </thead>
      <tbody>
        
        <tr *ngIf="activeDoc" >{{activeDoc.request.request}}</tr> 
        <tr>
          <!--  <button class='btn btn-primary' (click)='getSelectedForm()' >Get Form</button>-->
        </tr>
        <tr *ngFor="let form of forms" (click)= "setSelectedForm(form)" [ngClass] = "{'active': selectedId == form.request.rfmNumber}">
          
          
          <!-- <td  (click)="setSelectedId($event)">{{form.request.rfmNumber}}</td>   -->
          <td>{{form.request.timeStamp|date:'short'}}</td>
          <td>{{form.request.rfmNumber.substring(0,5)}}</td>
          <td >{{form.formState  }}</td>
          <td >{{form.request.requestorName  }}</td>
          
          <td>{{form.request.processArea}}</td>
          <td>{{form.request.request.substring(0,50)}}</td>
          
        </tr>
      </tbody>
      
    </table>
  </div>
