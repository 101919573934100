import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Rfm, Manager } from '../models/rfm';
import { Location, Approval, ILocation } from '../models/location'
import { LocationService } from '../services/location.service';
import { AuthService } from '../services/auth.service';
import { FormService } from '../services/form.service'
import { LocationStrategy } from '@angular/common';
import * as _ from 'lodash';


@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css'],
  providers: [LocationService, FormService],
})
export class ApprovalComponent {
 
  signatureGroups = [];
  signature = {};
  locations: Location[];
  firebaseUID = '';
  firebaseName = '';
  
  managers = Array<Manager>();
  activeDocCopy : Rfm;

  @Input('activeDoc') activeDoc: Rfm;

  constructor(private locationService: LocationService, public auth: AuthService, private formservice: FormService) {

    this.auth.user$.subscribe(user => {
      this.firebaseUID = user.uid;
      this.firebaseName = user.displayName;
    });
   // console.log(this.managers)
   this.activeDocCopy = new Rfm();

  }

  ngOnChanges() {
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as ILocation
        };//as any;
      })//end data.map
      this.activeDocCopy = _.cloneDeep(this.activeDoc);
      // insert code to work on data after return here
      let currentLocation: Location;
      let tempArray = [];
      this.managers = this.activeDoc.managers;
      if (this.locations !== undefined && this.activeDoc !== undefined) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.activeDoc.request.processArea == this.locations[i]['name']) {
            currentLocation = this.locations[i];
          }
          else {
            continue;
          }
        }

        for (let group in currentLocation) {

          if (currentLocation[group]['groupName'] && currentLocation[group]['groupName'].endsWith("Manager")) {
            tempArray.push(
              currentLocation[group]
            );
            let tmpString = currentLocation[group]['groupName'];
            this.signature[tmpString] = '';
          }
        }
      }
      this.signatureGroups = [].concat.apply([], tempArray);
    }// end data arrow function
    );//end subscribe
  }//end ngonit
  

  onSubmit() {

    let tempRole;
    let tempSig;
    let allSignatures = false;
    //set timestamp of post / change for user who sumbitted
    for(let prop in this.activeDocCopy.managers){
      if(this.activeDocCopy.managers[prop]['signature'] == this.firebaseName){
        this.activeDocCopy.managers[prop]['timeStamp'] = new Date().toISOString();
      }
       
    }

    
    //this.activeDoc = _.cloneDeep(this.activeDocCopy);
    // for (let name in this.signature) {
    //   if (this.signature[name]) {
    //     tempRole = name;
    //     tempSig = this.signature[name];
    //   }
    // }

    // OLD CODE
    //console.log(this.activeDocCopy.managers)
    // for (let managerRole in this.activeDoc['managers']) {
    //   if (tempRole == managerRole) {
    //     this.activeDoc['managers'][managerRole]['signature'] = tempSig;
    //     this.activeDoc['managers'][managerRole]['comment'] = this.managerComment;
    //     this.activeDoc['managers'][managerRole]['timeStamp'] = new Date().toISOString();
    //   }
    // } 
   
    allSignatures = true;
    for (let manager in this.activeDocCopy['managers']) {
      if(this.activeDocCopy['managers'][manager]['signature'] == this.firebaseName){

      }
      if (!this.activeDocCopy['managers'][manager]['signature'] || !this.activeDocCopy['managers'][manager]['approved'])
           allSignatures = false;
    }
    this.activeDoc = _.cloneDeep(this.activeDocCopy);
    if (allSignatures){
      this.activeDoc.formState = 'approved';
    }

    this.formservice.updateForm(this.activeDoc).then(e => {
    })
  }
}
