<h2>System Engineer only below</h2>
<h1>{{activeDoc.formState}}</h1>
<form>
    {{activeDocCopy.engineer.engineerName}} - {{activeDocCopy.engineer.timeStamp}date:'short'}
    <div class = 'row no-gutters'>
        <input type='checkbox' id='implemented' [(ngModel)]='implemented'>  
        <label for='implemented'>Implementation Complete</label>
    </div>
    <div class="row no-gutters">
        
        <div class="col">
            <label for="change-details">Details of Change:</label>
            <textarea [(ngModel)]='activeDocCopy.engineer.changeTest' name='changeDetails' id='change-details' class='form-control'></textarea>
        </div>  
    </div>
    <div class="row no-gutters">
        <div class="col">
            <label for="drawings-modified">Drawings Modified:</label>
            <textarea id='drawings-modified' [(ngModel)]='activeDocCopy.engineer.drawingChanges' name='drawingsModified' class='form-control'></textarea>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col">
            <div class="custom-file">
                <input type="file" [(ngModel)]='fileAttachments' name='fileAttachments' class="custom-file-input" id="customFile">
                <label class="custom-file-label" for="customFile">Attach relevant files</label>
            </div>
        </div>
    </div>
    <button class="btn btn-primary" (click)='submit()' [disabled] = '!authorizedEngineer'>Submit</button>
</form>
