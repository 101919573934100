import { Component, OnInit, Input,ChangeDetectorRef } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LocationService } from '../services/location.service';
import { Location } from '../models/location'
import { AppUser } from '../models/app-user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Extractor } from '@angular/compiler';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  userName: string;
  email: string;
  password: string;
  isAdmin: boolean;
  locations: Location[];
  userLocations: Location[]; //{uid:'1123322',roles:[locationName:'quarry',role[role.nam]]}
  //restaurants$: Observable<RestaurantModel[]>;
  @Input() userLocationRoles: [{}];//array of user roles in
  users: AppUser[];
  groups: [{}];
  areas: [{}];
  nUser: AppUser;
  selectedId: String;
  createUserToggle: boolean = false;
  editUserToggle: boolean = false;
  locationToggle: boolean = false;
  
  //locations: any;

  constructor(private adminServ: AdminService, private locationService: LocationService,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private firestore: AngularFirestore,
    private changeDetectorRef:ChangeDetectorRef) {
    this.userName = '';
    this.email = '';
    this.password = '';
    this.isAdmin = false;
    this.groups = [{}];
    this.areas = [{}];
    this.nUser = new AppUser();

    //this.locations= new Array();
  }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Location
        };//as any;
      })
      this.userLocationRoles = [{}];
      this.areas.pop();// pop the first blank index off
      for (let location of this.locations) {
        //this.areas["Location"] = location;
        this.groups = [{}];
        this.groups.pop(); // pop the blank record
        for (let prop in location) {// if the object has the property

          if (Object.prototype.hasOwnProperty.call(location, prop))
            if (prop.startsWith('group')) {  // and property is one of the groups
              if (location[prop]['groupName']) {
                let prtyname = location[prop]['groupName'];
                let obj = {};
                obj['Role'] = prtyname;
                obj[prtyname] = false
                this.groups.push(obj)
              }
              // console.log(location[prop]['groupName'])
            }
        }
        // this.areas["Location"][location.name] = this.groups;
        this.groups.sort((a, b) => (a['Role'] > b['Role']) ? 1 : -1);
        this.areas.push({ groupName: location.name, groups: this.groups });
        //this.areas[location.name]=this.groups;
      }
    }); // end locationserve get locations

    //GET USERS
    this.userService.getUsers().subscribe(data => {
      this.users = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as AppUser
        };//as any;
      })
    })

    //end GET USERS
  }
  setLocation(){
    this.locationToggle = true;
    this.createUserToggle = false;
    this.editUserToggle = false;

  }
  setEditUser(){
    this.createUserToggle = false;
    this.locationToggle = false;
  this.editUserToggle = true;
  }
  setNewUser(){
    this.createUserToggle = true;
    this.locationToggle = false;
  this.editUserToggle = false;
  }
  resetAdmin(){
    this.createUserToggle = false;
    this.locationToggle = false;
  this.editUserToggle = false;
  //this.changeDetectorRef.detectChanges();
  
  
  }
  async setSelectedId(id) {
    this.userLocations = [];
    this.userLocationRoles = [{}];

    this.selectedId = id;

    

    for (let location of this.locations) {
      
      this.userLocations.pop();
      for (let prop in location) {// if the object has the property
        if (Object.prototype.hasOwnProperty.call(location, prop))
          if (prop.startsWith('group')) {  // and property is one of the groups
            if (location[prop]['groupName'] !== '') {

              let users = location[prop]['users'];
              // let tmpArray = [{ 'location': location.name, 'roles': [{ 'role': location[prop]['groupName'] }] }]
              let userFrmGrp = users.find((data) => data.uid === id);
              if (userFrmGrp) {
                let idx = users.findIndex((element)=>element.uid === id);
                this.userLocationRoles['userId'] = id;
                    this.userLocationRoles['name'] = users[idx]['name'];
                     this.userLocationRoles['email'] = users[idx]['email'];
                if (this.userLocationRoles) {
                  if (Object.prototype.hasOwnProperty.call(this.userLocationRoles, location.name)) {
                    this.userLocationRoles[location.name].roles.push(location[prop]['groupName'])
                  }
                  else {
                      this.userLocationRoles[location.name] = { 'name': location.name, 'roles': [] };
                      this.userLocationRoles[location.name].roles.push(location[prop]['groupName']);
                    }
                }
                
              }
              else{

                //let idx = users.findIndex((element)=>element.uid === id);
                let idx = this.users.findIndex((element)=>element['id'] === this.selectedId)
                this.userLocationRoles['userId'] = this.users[idx]['id'];//this.selectedId;
                this.userLocationRoles['name'] = this.users[idx]['name'];
                this.userLocationRoles['email'] = this.users[idx]['email'];
              
               }
              

            }
            // console.log(location[prop]['groupName'])
          }
        //console.log(this.userLocationRoles)
      }
     
    }

    



  }

  deleteUser() {

  }
  updateUser() {

  }

  createUser() {

    this.nUser.name = this.userName;
    this.nUser.email = this.email;
    this.nUser.isAdmin = this.isAdmin;

    // put a check here to make sure we do not call this if the user already exists
    this.afAuth.createUserWithEmailAndPassword(this.nUser.email, this.password).then(fbuser => {
      fbuser.user.updateProfile({ displayName: this.nUser.name }).then(e => {
        this.firestore.collection('users').doc(fbuser.user.uid).set(
          {
            name: this.nUser.name, email: this.nUser.email, isAdmin: this.nUser.isAdmin,
            isRequestor: false, isEngineer: false, isManager: false
          })
          .then(e => {
            console.log("user created");
          })

        for (let loc of this.areas) {
          for (let group of loc['groups']) {
            //console.log(group)
            let ids = [];
            if (group[group['Role']] == true) {
              const tmp = group['Role'];
              let data = this.firestore.collection('location', ref => ref.where('name', '==', loc['groupName'])) //match up checkbox role selected to location name
                .valueChanges({ idField: 'id' })
                .subscribe(data => {

                  for (let obj in data[0] as any) {
                    // if(data[0][obj]['users'].push())
                    if (data[0][obj]['groupName'] == tmp) {
                      let foundName = false;
                      for (let u of data[0][obj]['users']) {
                        if (u.name == this.nUser.name)
                          foundName = true;

                      } //end for

                      if (foundName == false) {
                        if (data[0][obj]['users'])
                          data[0][obj]['users'].push({ name: this.nUser.name, email: this.nUser.email, uid: fbuser.user.uid })
                        // for (let i = 0; i < data[0][obj]['users'].length; i++)
                        //   console.log(loc['groupName'] + " " + data[0][obj]['users'][i]['name'] +
                        //     data[0][obj]['users'][i]['email'] + data[0][obj]['users'][i]['uid'])
                        let tmpId = data[0]['id'];
                        const docRef = this.firestore.collection('location').doc(tmpId);
                        const res = docRef.update(data[0]);

                      }
                    }

                  }

                })
            }
          }
        }
      })//end update profile then
    })//end of observable createUserWithEmail then
  }



}
